const { simplify } = require('@turf/turf');
const {
  default: createSubstituteGeometry
} = require('./createSubstituteGeometry');

const simplifyFeatures = (features, tolerance) => {
  return features.map(feature => {
    try {
      // Attempt to simplify the feature
      return simplify(feature, { tolerance, highQuality: true });
    } catch (error) {
      // If simplification fails, use a substitute geometry
      return createSubstituteGeometry(feature);
    }
  });
};

export default simplifyFeatures;
