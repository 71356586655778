import { Maths } from 'helpers/maths';

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getToken = headers => {
  let auth;
  if (headers.Authorization !== undefined) {
    auth = headers.Authorization;
  }
  // the lowercase authorization is in place because the fetch() lib toLower()'s all the headers :(
  if (headers.authorization !== undefined) {
    auth = headers.authorization;
  }
  if (auth !== undefined) {
    const bearerToken = auth.split(' ')[1];
    const token = parseJwt(bearerToken);
    return token;
  }
  return null;
};

export const getOriginalTotalArea = properties =>
  properties.reduce(
    (prev, curr) => prev + (curr.reportedArea || curr.originalAcres),
    0
  );

export const adjustZoneAcreValues = (coveragePercent, oldPercent, properties) =>
  properties.map(property => {
    let pct = 0;
    if (Number.isFinite(oldPercent / 100)) {
      pct = coveragePercent / 100;
    }
    const originalAcres = property.reportedArea || property.originalAcres;
    const areaValue = pct * originalAcres;
    return {
      ...property,
      areaValue: Maths.parseFloatRound(areaValue),
      coveragePct: Maths.parseFloatRound(pct * 100)
    };
  });

export const getStringParsed = (value, defaultValue = null) => {
  try {
    return JSON.parse(value);
  } catch {
    return defaultValue;
  }
};

/**
 * Replaces all undefined and empty string values in an object with null.
 * This function processes nested objects recursively.
 *
 * @param {Object} obj - The object to process.
 * @returns {Object} A new object with all undefined and empty string values replaced with null.
 */
export const replaceUndefinedAndEmptyWithNull = obj => {
  if (typeof obj !== 'object' || obj === null || obj instanceof Date) {
    return obj;
  }

  const newObj = Array.isArray(obj) ? [] : {};
  Object.entries(obj).forEach(([key, value]) => {
    if (
      value === undefined ||
      value === '' ||
      (typeof value === 'string' && !value.trim().length)
    ) {
      newObj[key] = null;
    } else if (typeof value === 'object') {
      newObj[key] = replaceUndefinedAndEmptyWithNull(value);
    } else {
      newObj[key] = value;
    }
  });
  return newObj;
};
