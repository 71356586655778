/* eslint-disable import/prefer-default-export */
import { convert } from '@agconnections/agc_unitconversion';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

export const specialCaseUnit = [
  'bag',
  'box',
  'bulk',
  'bushel',
  'can',
  'crate',
  'dispenser',
  'kernal',
  'kernel',
  'load',
  'package',
  'plant',
  'roll',
  'seed',
  'tablet',
  'tray',
  'pound'
];

const invalidUnits = ['kg', 'feet', 'cubic feet', 'square feet'];
const SERVICE_UNITS = ['second', 'hour', 'day', 'year'];
const FERTILIZER_UNITS = [
  'acre',
  'acre inch',
  'centiliter',
  'cubic foot',
  'cubic inch',
  'cubic meter',
  'cubic yard',
  'fluid ounce',
  'gallon',
  'liter',
  'milliliter',
  'pint',
  'quart',
  'gram',
  'kilogram',
  'long ton',
  'metric ton',
  'ounce',
  'pound',
  'ton'
];

/*
  https://www.omnicalculator.com/physics/specific-gravity
  we are expecting the density is in grams per cubic centimeter g/cm3
*/

const calculateSpecificGravity = density => (density ? density / 1.0 : 0);

const cleanCompatibleUnits = possibilities => {
  const possibleUnitConversions = [];

  possibilities.forEach(unit => {
    if (!unit.endsWith('s') && !invalidUnits.includes(unit.toLowerCase())) {
      possibleUnitConversions.push(unit);
    }
  });

  return possibleUnitConversions;
};

const unitAbbreviation = units =>
  units.map(item => {
    return {
      key: item.key,
      value: getAbbreviatedUnit(item.value)
    };
  });

const removeDuplicates = units => [...new Set(units)];

const convertToOptions = possibilities =>
  possibilities.map(unit => ({ key: unit, value: unit }));

export const getCompatibleUnits = (units, density) => {
  const mainUnit = units[0];
  const packageUnit = units[1];
  const possibilities = [];
  if (
    !specialCaseUnit.includes(mainUnit) &&
    !specialCaseUnit.includes(packageUnit)
  ) {
    const convertUnits = convert(0, calculateSpecificGravity(density))
      .from(mainUnit)
      .possibilities();
    possibilities.push(...cleanCompatibleUnits(convertUnits));
  } else {
    possibilities.push(...units);
    if (
      packageUnit !== 'unit' &&
      (mainUnit === 'kernal' ||
        mainUnit === 'kernel' ||
        mainUnit === 'seed' ||
        mainUnit === 'pound')
    ) {
      possibilities.push('bag');
    }
  }

  if (
    SERVICE_UNITS.includes(mainUnit) &&
    !specialCaseUnit.includes(packageUnit)
  ) {
    possibilities.push(...SERVICE_UNITS);
  } else if (
    FERTILIZER_UNITS.includes(mainUnit) &&
    !specialCaseUnit.includes(packageUnit)
  ) {
    possibilities.push(...FERTILIZER_UNITS);
  }

  return unitAbbreviation(
    convertToOptions(removeDuplicates([...possibilities, mainUnit]))
  );
};

export const roundValue = valueToRound =>
  Math.round((valueToRound + Number.EPSILON) * 100) / 100;

export const getConvertUnitFromTo = (
  value,
  fromUnit,
  toUnit,
  { density, productDensity, stdfactor, stdunit, stdpackageunit } = {}
) => {
  if (value === 0) return { Value: 0 };

  if (!value || !fromUnit || !toUnit) {
    return { Value: 1 }; // ????
  }

  if (fromUnit === toUnit) return { Value: value };

  if (specialCaseUnit.includes(fromUnit)) {
    if (fromUnit === stdunit && toUnit === stdpackageunit) {
      return { Value: value / stdfactor };
    }
    if (fromUnit === stdpackageunit && toUnit === stdunit) {
      return { Value: value * stdfactor };
    }
    throw new Error('unknown conversion');
  }

  const actualDensity = density || productDensity;
  return convert(value, actualDensity)
    .from(fromUnit)
    .to(toUnit)
    .toJSON();
};

export const setConversionFactor = (areSame, valueToInvert) =>
  areSame ? 1 / valueToInvert : valueToInvert;

export const calcSpecialCase = (
  unitIsSame,
  totProduct,
  rate,
  conversionFactor,
  unitIsPackageUnit
) => {
  let newValue = 0;
  const newConversionFactor = !unitIsSame
    ? setConversionFactor(unitIsPackageUnit, conversionFactor)
    : 1;
  if (rate > 0) {
    newValue = !unitIsSame
      ? totProduct / newConversionFactor / rate
      : totProduct / rate;
  }

  return newValue;
};

export const unitsAreSame = (firstUnit, secondUnit) => firstUnit === secondUnit;

export const calcConversions = (
  convertValue,
  fromUnit,
  toUnit,
  bothUnitsAreSame,
  product
) => {
  let converted = 0;
  if (bothUnitsAreSame) {
    converted = convertValue;
  } else {
    const totalConversion = getConvertUnitFromTo(
      Number.parseFloat(convertValue),
      fromUnit,
      toUnit,
      product
    );
    converted = totalConversion?.Value;
  }

  return converted;
};

const byRatePerArea = ['ratePerAreaValue', 'ByRatePerArea'];
const byTotalProduct = ['totalProductValue', 'ByTotalProduct'];
const byRatePerTank = ['ratePerTankValue', 'ByRatePerTank'];

export const calculateProductValues = (
  valueChanged,
  targetValue,
  changedUnits,
  changedAppArea,
  newPrice,
  productToAdd = {},
  values = {}
) => {
  let totalProduct = 0;
  let ratePerTank = 0;
  let ratePerArea = 0;
  let totalCost = 0;
  const appArea = changedAppArea?.changedArea || productToAdd.appliedAreaValue;
  const appPct = changedAppArea?.changedPct || productToAdd.coveragePercent;
  const rateUnitValue = changedUnits?.rateUnit || productToAdd.ratePerAreaUnit;
  const totUnitValue =
    changedUnits?.totalProdUnit || productToAdd.totalProductUnit;
  const tankUnitValue = changedUnits?.tankUnit || productToAdd.ratePerTankUnit;
  const priceBag = newPrice || productToAdd.averagePriceAtTimeOfCreation;
  const tankCount = values.tankInformation?.tankCount;

  const rateUnitIsPackageUnit = unitsAreSame(
    rateUnitValue,
    productToAdd.stdpackageunit
  );

  const tankUnitIsPackageUnit = unitsAreSame(
    tankUnitValue,
    productToAdd.stdpackageunit
  );

  const totalUnitIsPackageUnit = unitsAreSame(
    totUnitValue,
    productToAdd.stdpackageunit
  );

  const specialCase = specialCaseUnit.includes(productToAdd.stdpackageunit);
  let conversionFromRateToStd = 1;
  let conversionFromTankToStd = 1;

  if (
    !specialCase &&
    !specialCaseUnit.includes(rateUnitValue) &&
    !specialCaseUnit.includes(tankUnitValue)
  ) {
    const actualDensity = productToAdd.density || productToAdd.productDensity;
    const conversion = convert(1, actualDensity);
    conversionFromRateToStd = conversion
      .from(productToAdd.stdunit)
      .to(rateUnitValue)
      .toJSON()._value;
    conversionFromTankToStd = conversion
      .from(productToAdd.stdunit)
      .to(tankUnitValue)
      .toJSON()._value;
  }

  const conversionFactor = productToAdd.stdfactor;
  const rateUnitConversionFactor = conversionFactor * conversionFromRateToStd;
  const tankUnitConversionFactor = conversionFactor * conversionFromTankToStd;

  if (byRatePerArea.includes(valueChanged)) {
    const totalUnitIsSame = unitsAreSame(rateUnitValue, totUnitValue);
    const tankUnitIsSame = unitsAreSame(totUnitValue, tankUnitValue);
    const totalProd = targetValue * appArea;

    if (specialCase) {
      totalProduct = calcSpecialCase(
        totalUnitIsSame,
        totalProd,
        1,
        rateUnitConversionFactor,
        rateUnitIsPackageUnit
      );
      ratePerTank = calcSpecialCase(
        tankUnitIsSame,
        totalProduct,
        tankCount,
        tankUnitConversionFactor,
        totalUnitIsPackageUnit
      );
    } else {
      totalProduct = calcConversions(
        totalProd,
        rateUnitValue,
        totUnitValue,
        totalUnitIsSame,
        productToAdd
      );

      if (tankCount > 0) {
        const rateTank = totalProduct / tankCount;
        ratePerTank = calcConversions(
          rateTank,
          rateUnitValue,
          tankUnitValue,
          tankUnitIsSame
        );
      }
    }

    ratePerArea = targetValue;
  }

  if (byTotalProduct.includes(valueChanged)) {
    const rateUnitIsSame = unitsAreSame(totUnitValue, rateUnitValue);
    const tankUnitIsSame = unitsAreSame(totUnitValue, tankUnitValue);

    if (specialCase) {
      ratePerArea = calcSpecialCase(
        rateUnitIsSame,
        targetValue,
        appArea,
        rateUnitConversionFactor,
        totalUnitIsPackageUnit
      );

      ratePerTank = calcSpecialCase(
        tankUnitIsSame,
        targetValue,
        tankCount,
        tankUnitConversionFactor,
        totalUnitIsPackageUnit
      );
    } else {
      if (appArea > 0) {
        const rateArea = targetValue / appArea;

        ratePerArea = calcConversions(
          rateArea,
          totUnitValue,
          rateUnitValue,
          rateUnitIsSame,
          productToAdd
        );
      }

      if (tankCount > 0) {
        const rateTank = targetValue / tankCount;

        ratePerTank = calcConversions(
          rateTank,
          totUnitValue,
          tankUnitValue,
          tankUnitIsSame,
          productToAdd
        );
      }
    }

    totalProduct = targetValue;
  }

  if (byRatePerTank.includes(valueChanged)) {
    if (tankCount > 0) {
      const rateUnitIsSame = unitsAreSame(tankUnitValue, rateUnitValue);
      const totalUnitIsSame = unitsAreSame(tankUnitValue, totUnitValue);
      const totProd = targetValue * tankCount;

      if (specialCase) {
        ratePerArea = calcSpecialCase(
          rateUnitIsSame,
          totProd,
          appArea,
          rateUnitConversionFactor,
          tankUnitIsPackageUnit
        );

        totalProduct = calcSpecialCase(
          totalUnitIsSame,
          totProd,
          1,
          rateUnitConversionFactor,
          tankUnitIsPackageUnit
        );
      } else {
        if (appArea > 0) {
          const rateArea = totProd / appArea;

          ratePerArea = calcConversions(
            rateArea,
            tankUnitValue,
            rateUnitValue,
            rateUnitIsSame,
            productToAdd
          );
        }

        totalProduct = calcConversions(
          totProd,
          tankUnitValue,
          totUnitValue,
          totalUnitIsSame,
          productToAdd
        );
      }
    }

    ratePerTank = targetValue;
  }

  if (totalUnitIsPackageUnit) {
    totalCost = totalProduct * priceBag;
  } else {
    const totalProductPkgUnit = specialCase
      ? calcSpecialCase(
          totalUnitIsPackageUnit,
          totalProduct,
          appArea,
          rateUnitConversionFactor,
          totalUnitIsPackageUnit
        )
      : calcConversions(
          totalProduct,
          totUnitValue,
          productToAdd?.stdpackageunit,
          totalUnitIsPackageUnit,
          productToAdd
        );
    totalCost = totalProductPkgUnit * priceBag;
  }

  return {
    ...productToAdd,
    totalProductValue: totalProduct,
    ratePerAreaValue: ratePerArea,
    ratePerTankValue: ratePerTank,
    totalCostAtTimeOfCreation: totalCost,
    averagePriceAtTimeOfCreation: priceBag,
    ratePerAreaUnit: rateUnitValue,
    ratePerTankUnit: tankUnitValue,
    totalProductUnit: totUnitValue,
    appliedAreaValue: appArea,
    coveragePercent: appPct
  };
};

export const calculateNumberOfApplications = (
  productToAdd,
  numberOfApps = 1
) => {
  const {
    appliedAreaValue,
    ratePerAreaValue,
    totalProductUnit,
    averagePriceAtTimeOfCreation,
    stdunit,
    stdpackageunit,
    stdfactor,
    density,
    productDensity,
    ratePerAreaUnit
  } = productToAdd;

  let totalProductValue = ratePerAreaValue * appliedAreaValue * numberOfApps;

  if (ratePerAreaUnit !== stdunit) {
    const conversionToStdUnit = getConvertUnitFromTo(
      totalProductValue,
      ratePerAreaUnit,
      stdunit,
      { density, productDensity, stdfactor, stdunit, stdpackageunit }
    ).Value;
    totalProductValue = conversionToStdUnit;
  }

  if (stdunit !== totalProductUnit) {
    const conversionToTotalProductUnit = getConvertUnitFromTo(
      totalProductValue,
      stdunit,
      totalProductUnit,
      { density, productDensity, stdfactor, stdunit, stdpackageunit }
    ).Value;
    totalProductValue = conversionToTotalProductUnit;
  }

  const totalCostAtTimeOfCreation =
    totalProductValue * averagePriceAtTimeOfCreation;

  return {
    ...productToAdd,
    applicationCount: numberOfApps,
    totalProductValue: roundValue(totalProductValue),
    totalCostAtTimeOfCreation: roundValue(totalCostAtTimeOfCreation)
  };
};
