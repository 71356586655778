import { PROPERTY_VIEW_MODE } from './constants';

/**
 * Checks if the view mode should be reset to the default map view.
 * @param {Object} params - Parameters for the function.
 * @param {Object} params.selectedProperty - The currently selected property.
 * @param {string} params.currentView - The current view mode.
 * @param {boolean} params.costAnalysisViewEnabled - Flag indicating if the cost analysis view is enabled.
 * @returns {boolean} - Returns true if the view mode should be reset to map view.
 */
export default function shouldResetViewMode({
  selectedProperty,
  currentView,
  isCostAnalysisViewEnabled
}) {
  const whenHasNorPropertySelected =
    !selectedProperty?.id && currentView !== PROPERTY_VIEW_MODE.MAP;

  const whenUserChangeToFarmAndViewModeIsCost =
    selectedProperty?.id &&
    currentView === PROPERTY_VIEW_MODE.COST &&
    selectedProperty.type === 'Farm';

  const whenTheFlagIsOffForCostViewMode =
    !isCostAnalysisViewEnabled && currentView === PROPERTY_VIEW_MODE.COST;

  return (
    whenHasNorPropertySelected ||
    whenUserChangeToFarmAndViewModeIsCost ||
    whenTheFlagIsOffForCostViewMode
  );
}
