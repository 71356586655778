import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  differenceInMinutes,
  addMinutes,
  compareAsc,
  subMinutes
} from 'date-fns';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import {
  Input,
  Select,
  DatePicker,
  Label,
  Spinner
} from '@agconnections/grow-ui';
import TagsModal from 'components/Modals/TagsModal';
import TagsButton, {
  extraTagsCalculation,
  TagsPopUpMessage
} from 'components/TagsButton';
import { STORAGE_KEYS } from 'utilities/localStorage';
import ListWithPopup from 'components/ListWithPopup';
import alphanumSort from 'helpers/sorters';
import useWeatherData from '../../../../helpers/useWeatherData';

const itemsTaskStatus = [
  { key: 'ready', value: 'Ready' },
  { key: 'inprogress', value: 'In Progress' },
  { key: 'complete', value: 'Complete' }
];

const TaskInfo = ({
  people,
  peopleLoading,
  weatherAutomaticToggle,
  memberRole,
  memberFinancialAccess
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [openTags, setOpenTags] = useState(false);
  const [taskTags, setTaskTags] = useState(values?.flags || []);
  const [displayTagsObject, setDisplayTagsObject] = useState({
    extraTagsCount: 0,
    extraTagsNames: [],
    tagsToDisplay: []
  });
  const { TaskWeatherObj } = useWeatherData();
  const clearWeatherData = status => {
    if (status !== 'complete' && weatherAutomaticToggle) {
      setFieldValue('weather', [TaskWeatherObj]);
    }
  };
  const durationItems = [
    { key: '0', value: '0 Hours' },
    { key: '0.5', value: '0.5 Hours' },
    { key: '1', value: '1 Hour' },
    { key: '1.5', value: '1.5 Hours' },
    { key: '2', value: '2 Hours' },
    { key: '2.5', value: '2.5 Hours' },
    { key: '3', value: '3 Hours' },
    { key: '3.5', value: '3.5 Hours' },
    { key: '4', value: '4 Hours' },
    { key: 'Custom', value: 'Custom Hours' }
  ];

  const mappedPeople = alphanumSort(
    people
      ? people.map(person => {
          return { key: person.id, value: person.name };
        })
      : [],
    'value'
  );

  const getDisabled = () => {
    if (
      memberRole === 'Full control' &&
      (memberFinancialAccess === 'none' || memberFinancialAccess === 'READ')
    )
      return true;

    return false;
  };

  const handleSelectedTags = async e => {
    setFieldValue('flags', e);
    setTaskTags(e);
  };

  useEffect(() => {
    if (taskTags?.length > 0)
      setDisplayTagsObject(extraTagsCalculation(taskTags, 55));
    if (values.flags) {
      setTaskTags(values.flags);
    }
  }, [taskTags, values.flags]);

  const getCustomDuration = duration => {
    const customDuration = durationItems?.find(
      durationItem => durationItem.key === duration.toString()
    );
    return customDuration || 'Custom';
  };

  return (
    <>
      <TagsModal
        open={openTags}
        close={() => setOpenTags(false)}
        name={values?.name}
        selectedTags={
          taskTags?.length > 0
            ? taskTags.reduce((tagIds, tag) => {
                return `${tagIds},${tag.id}`;
              }, '')
            : ''
        }
        returnSelectedTags={handleSelectedTags}
        setFieldTouchedParent={setFieldTouched}
      />
      <GrowUIFormField label="Task ID *" control={Input} name="name" />
      <GrowUIFormField
        label="Task Status *"
        control={Select}
        name="status"
        items={itemsTaskStatus}
        onChange={e => {
          if (!e.target.value.includes(values.status)) {
            setFieldTouched('Task Status');
          }
          const changedVal = JSON.parse(e.target.value).key;
          clearWeatherData(changedVal);
          setFieldValue('status', changedVal);
        }}
      />
      <GrowUIFormField
        label="Start Date & Time *"
        control={DatePicker}
        enableTime
        name="startDate"
        dateFormat="F j,  Y, h:i K"
        onChange={e => {
          setFieldTouched('Start Date & Time');
          const changedStartDate = e.target.value[0];
          const minutes = differenceInMinutes(values.dueDate, changedStartDate);
          const duration = getCustomDuration(minutes / 60);
          if (values.duration !== 'Custom') {
            setFieldValue(
              'dueDate',
              addMinutes(changedStartDate, Number(values.duration) * 60)
            );
          } else {
            const currentDurration = differenceInMinutes(
              values.dueDate,
              values.startDate
            );
            setFieldValue('startDate', e.target.value[0]);
            if (compareAsc(e.target.value[0], values.dueDate) === 1) {
              setFieldValue(
                'dueDate',
                addMinutes(e.target.value[0], currentDurration)
              );
            } else {
              setFieldValue('duration', duration.key || duration);
            }
          }
          setFieldValue('startDate', changedStartDate);
        }}
      />
      <div className="grid items-end grid-cols-2 gap-12">
        <GrowUIFormField
          label="Duration *"
          control={Select}
          name="duration"
          disabled={getDisabled()}
          items={durationItems}
          onChange={e => {
            const changedDuration = JSON.parse(e.target.value).key;
            setFieldValue('duration', changedDuration);
            if (changedDuration !== 'Custom') {
              setFieldValue(
                'dueDate',
                addMinutes(values.startDate, Number(changedDuration) * 60)
              );
            }
          }}
        />
        <GrowUIFormField
          label="End Date & Time *"
          control={DatePicker}
          enableTime
          name="dueDate"
          dateFormat="F j,  Y, h:i K"
          onChange={e => {
            const minutes = differenceInMinutes(
              e.target.value[0],
              values.startDate
            );
            const duration = getCustomDuration(minutes / 60);
            setFieldValue('dueDate', e.target.value[0]);
            if (compareAsc(values.startDate, e.target.value[0]) === 1) {
              if (values.duration !== 'Custom') {
                setFieldValue(
                  'startDate',
                  subMinutes(e.target.value[0], Number(values.duration) * 60)
                );
              } else {
                setFieldValue('startDate', e.target.value[0]);
                setFieldValue('duration', '0');
              }
            } else {
              setFieldValue('duration', duration.key || duration);
            }
          }}
        />
      </div>
      <GrowUIFormField
        label="Task Type"
        control={Select}
        name="type"
        items={[
          { key: 'General', value: 'General' },
          { key: 'Irrigation', value: 'Irrigation' },
          { key: 'Land prep', value: 'Land prep' },
          { key: 'Planting', value: 'Planting' },
          { key: 'Spraying', value: 'Spraying' },
          { key: 'Spreading', value: 'Spreading' }
        ]}
      />
      <GrowUIFormField
        label="Timing Event"
        control={Select}
        name="timingEvent"
        items={[
          { key: 'Pre-Plant', value: 'Pre-Plant' },
          { key: 'Planting', value: 'Planting' },
          { key: 'Growing', value: 'Growing' },
          { key: 'Harvest', value: 'Harvest' },
          { key: 'Storage', value: 'Storage' },
          { key: 'Annual', value: 'Annual' }
        ]}
      />
      <div>
        <Label absolute>Tags</Label>
        {displayTagsObject.extraTagsNames.length > 0 ? (
          <ListWithPopup
            position="bottom"
            ariaLabel="Task Tags"
            top="1.5rem"
            msg={
              <TagsPopUpMessage
                extraTagsNames={displayTagsObject.extraTagsNames}
              />
            }
            component={
              <TagsButton
                setOpenTags={setOpenTags}
                tags={taskTags}
                displayTagsObject={displayTagsObject}
                viewKey={STORAGE_KEYS.TASKS_VIEW}
                disabled={false}
              />
            }
          />
        ) : (
          <TagsButton
            setOpenTags={setOpenTags}
            tags={taskTags}
            displayTagsObject={displayTagsObject}
            viewKey={STORAGE_KEYS.TASKS_VIEW}
            disabled={false}
          />
        )}
      </div>

      {peopleLoading ? (
        <Spinner />
      ) : (
        <GrowUIFormField
          label="Authorized by"
          name="authorizerId"
          control={Select}
          items={mappedPeople}
          disabled={getDisabled()}
        />
      )}

      <GrowUIFormField
        label="Authorization Date"
        control={DatePicker}
        name="authorizedDate"
        dateFormat="F j, Y"
        onChange={e => {
          setFieldTouched('Authorization Date');
          setFieldValue('authorizedDate', e.target.value[0]);
        }}
      />

      {/* per reqs in LDBAPP-3122, removing Source, will be re-added in later story */}
      {/* <GrowUIFormField
        label="Source"
        control={Select}
        name="source"
        multiple
        items={[
          { key: 'Example Plan', value: 'Example Plan' },
          { key: 'Example Property', value: 'Example Property' }
        ]}
      /> */}
    </>
  );
};

TaskInfo.defaultProps = {
  people: [],
  peopleLoading: true,
  weatherAutomaticToggle: false
};

TaskInfo.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object),
  peopleLoading: PropTypes.bool,
  weatherAutomaticToggle: PropTypes.bool,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string.isRequired
};

export default TaskInfo;
