import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Spinner, Table, Tag, TagCloud } from '@agconnections/grow-ui';
import EllipseMenu from 'components/EllipseMenu';
import { RECOMMENDATIONS_LANDING_MENU } from 'utilities/menus';
import { paths } from 'routes/paths';
import { getSeasons, getSubtitle } from '../../../../helpers/list';
import RECOMMENDATION_TYPE, {
  RECOMMENDATION_TYPE_DISPLAY_NAME
} from '../../../../helpers/recommendationType';
import useRecEllipseOptions from '../../../../hooks/useRecEllipseMenu';

const MAX_TAGS_SHOWN = 3;

const trimFlags = (
  recommendationTagIds,
  allTags,
  maxTagsShown = MAX_TAGS_SHOWN
) => {
  const tags = recommendationTagIds.reduce((acc, flagId) => {
    const tag = allTags.find(t => t.id === flagId);
    return tag ? acc.concat([tag]) : acc;
  }, []);

  const trimmedTags = tags.slice(0, maxTagsShown);

  if (tags.length > maxTagsShown) {
    trimmedTags.push({
      id: 'additional-tag',
      name: `+${tags.length - maxTagsShown}`
    });
  }

  return trimmedTags;
};

function RecommendationTableRow({
  recommendation,
  cropSeasons,
  tags,
  onAction,
  runningProcesses
}) {
  const handleAction = action => onAction(action);
  const trimmedFlags = trimFlags(recommendation.flagIds ?? [], tags);
  if (recommendation?.taskCreated) {
    trimmedFlags.unshift({
      id: 'task-created',
      name: 'task created',
      color: '#73DB78' // cropwise-green
    });
  }
  const { filterMenuItems } = useRecEllipseOptions();
  return (
    <Table.Row>
      <Table.Cell>
        <div className="flex flex-col items-start ">
          <span className="text-sm font-bold">
            <Link to={`${paths.recommendations}/${recommendation.id}/view`}>
              {recommendation.title}
            </Link>
          </span>
          {trimmedFlags.length > 0 && (
            <TagCloud>
              {trimmedFlags.map(tag => (
                <Tag key={tag.id} color={tag.color}>
                  {tag.name}
                </Tag>
              ))}
            </TagCloud>
          )}
        </div>
      </Table.Cell>
      <Table.Cell>{recommendation.cropZones.length}</Table.Cell>
      <Table.Cell>
        {recommendation.cropZones
          .reduce((acc, zone) => {
            return acc + zone?.areaValue;
          }, 0)
          .toFixed(2)}
      </Table.Cell>
      <Table.Cell>
        {recommendation.recommendationProductEntries.length}
      </Table.Cell>
      <Table.Cell>{getSubtitle(recommendation)}</Table.Cell>
      <Table.Cell>
        {getSeasons({
          seasons: cropSeasons,
          seasonIds: recommendation.cropSeasonIds
        })}
      </Table.Cell>
      {/* TODO: Add logic for showing agrian status */}
      <Table.Cell>
        {recommendation.type === RECOMMENDATION_TYPE.agrian ? (
          <div className="flex flex-col justify-center items-start">
            <span>
              {RECOMMENDATION_TYPE_DISPLAY_NAME[RECOMMENDATION_TYPE.agrian]}
            </span>
            <span className="text-chili-red">Unresolved</span>
          </div>
        ) : null}
      </Table.Cell>
      <Table.Cell>
        <div
          data-testid="recommendation-table-options-menu"
          className="absolute right-0 flex-none w-10 mr-2"
        >
          {runningProcesses ? (
            <Spinner size="sm" />
          ) : (
            <EllipseMenu
              onAction={handleAction}
              options={filterMenuItems(RECOMMENDATIONS_LANDING_MENU)}
            />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

RecommendationTableRow.propTypes = {
  recommendation: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    cropZones: PropTypes.arrayOf(
      PropTypes.shape({
        areaValue: PropTypes.number
      })
    ),
    recommendationProductEntries: PropTypes.arrayOf(PropTypes.object),
    cropSeasonIds: PropTypes.arrayOf(PropTypes.string),
    expirationDate: PropTypes.string,
    proposedDate: PropTypes.string,
    createdOn: PropTypes.string,
    flagIds: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    taskCreated: PropTypes.bool
  }).isRequired,
  cropSeasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  ).isRequired,
  onAction: PropTypes.func.isRequired,
  runningProcesses: PropTypes.bool
};

RecommendationTableRow.defaultProps = {
  runningProcesses: false
};

export default RecommendationTableRow;
