import React, { useMemo, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Table } from '@agconnections/grow-ui';

import ViewIcon from 'assets/open_in_new.svg';
import CwFPagination from 'components/CwFPagination';
import LandingTable from 'components/LandingTable';
import tableSort from 'helpers/tableSort';
import useProductApplicationData from 'screens/ProductList/ProductDetails/hooks/useProductApplicationData';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { paths } from 'routes/paths';
import routesForPPLToCreate from 'screens/ProductList/helpers/creationRoutes';
import ProductDetailTableEmptyState from '../ProductDetailTableEmptyState';

const PAGE_SIZE = 20;

const ProductDetailTasksTable = ({ cropSeasons, dateRange }) => {
  const { id: productId } = useParams();
  const history = useHistory();
  const {
    getProductApplications,
    tasks,
    isLoading,
    sortBy,
    sortDir,
    sort
  } = useProductApplicationData(productId);

  const [pageNo, setPageNo] = useState(0);

  const TABLE_COLUMNS = [
    {
      field: 'name',
      title: `Task Name`,
      sortable: true
    },
    {
      field: 'completedDate',
      title: 'Completed Date',
      sortable: true
    },
    {
      field: 'ratePerArea',
      title: 'Rate',
      sortable: true
    },
    {
      field: 'appliedArea',
      title: 'Applied Area',
      sortable: true
    },
    {
      field: 'totalProduct',
      title: 'Applied Quantity',
      sortable: true
    },
    {
      field: 'taskId',
      title: '',
      sortable: false
    }
  ];

  const mappedTasks = useMemo(() => {
    const taskList = tasks.map(task => ({
      id: task.applicationId,
      name: task.applicationName,
      completedDate: new Date(task.completedDateTime),
      taskCropSeasons: task.cropSeasonIds,
      ratePerArea: task.ratePerAreaValue,
      ratePerAreaUnit: task.ratePerAreaUnit,
      appliedArea: task.appliedAreaValue,
      appliedAreaUnit: task.appliedAreaUnit,
      totalProduct: task.productQuantityValue,
      totalProductUnit: task.productQuantityUnit
    }));

    return taskList;
  }, [tasks]);

  const filteredAndSortedTasks = useMemo(() => {
    const filtered = mappedTasks.filter(row => {
      const taskDate = new Date(row.completedDate);
      const cropFilter =
        cropSeasons.length === 0 ||
        row.taskCropSeasons?.some(crop => cropSeasons.includes(crop.id));
      const dateFilter =
        !dateRange ||
        (taskDate > new Date(dateRange[0]) &&
          taskDate < new Date(dateRange[1]));
      return cropFilter && dateFilter;
    });
    return tableSort(filtered, sortBy, sortDir);
  }, [mappedTasks, sortBy, sortDir, cropSeasons, dateRange]);

  const totalProduct = useMemo(() => {
    const total = filteredAndSortedTasks.reduce((acc, task) => {
      return acc + task.totalProduct;
    }, 0);

    return `${total.toFixed(2)} ${getAbbreviatedUnit(
      filteredAndSortedTasks[0]?.totalProductUnit
    ) ?? ''}`;
  }, [filteredAndSortedTasks]);

  const paginatedData = filteredAndSortedTasks.slice(
    pageNo * PAGE_SIZE,
    (pageNo + 1) * PAGE_SIZE
  );

  const isEmpty = paginatedData.length === 0;

  useEffect(() => {
    getProductApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (
    <div className="flex" style={{ height: 'calc(100% - 3rem)' }}>
      <div className="flex-1 overflow-scroll">
        <LandingTable
          items={paginatedData}
          isLoading={isLoading}
          tableColumns={TABLE_COLUMNS}
          isEmpty={isEmpty}
          emptyState={() => null}
          onSort={(field, dir = 'asc') => sort(field, dir)}
          sortBy={sortBy}
          sortDir={sortDir}
          wrapperClassName="flex-1 h-fit"
        >
          {({ rows }) =>
            rows.map(task => (
              <Table.Row key={task.taskId}>
                <Table.Cell width="auto">{task.name}</Table.Cell>
                <Table.Cell width="auto">
                  {new Date(task.completedDate).toLocaleDateString()}
                </Table.Cell>
                <Table.Cell width="auto">
                  {`${task.ratePerArea.toFixed(2)} ${getAbbreviatedUnit(
                    task.ratePerAreaUnit
                  )}/${getAbbreviatedUnit(task.appliedAreaUnit)}`}
                </Table.Cell>
                <Table.Cell width="auto">{`${task.appliedArea.toFixed(
                  2
                )} ${getAbbreviatedUnit(task.appliedAreaUnit)}`}</Table.Cell>
                <Table.Cell width="auto">{`${task.totalProduct.toFixed(
                  2
                )} ${getAbbreviatedUnit(task.totalProductUnit)}`}</Table.Cell>
                <Table.Cell width="auto">
                  <div className="w-full flex flex-row justify-end">
                    <Link
                      to={`${paths.tasks}/${task.id}/view`}
                      className="flex flex-row"
                    >
                      <img className="mr-1" src={ViewIcon} alt="view icon" />
                      <span>View</span>
                    </Link>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))
          }
        </LandingTable>
        {!isLoading && !isEmpty && (
          <div className="w-full px-6 border-1 border-t-0 border-neutral-20">
            {CwFPagination(
              tasks.length,
              pageNo,
              setPageNo,
              () => (
                <div
                  className="bg-banner-alert-blue-10 rounded py-1 px-3"
                  data-testid="product-detail-total-value"
                >
                  <span>Total Applied Quantity: </span>
                  <span className="font-semibold">{totalProduct}</span>
                </div>
              ),
              PAGE_SIZE
            )}
          </div>
        )}
        {!isLoading && isEmpty && (
          <ProductDetailTableEmptyState
            type="completed_tasks"
            totalItemCount={mappedTasks.length}
            onButtonClick={() => history.push(routesForPPLToCreate.task)}
          />
        )}
      </div>
    </div>
  );
};

ProductDetailTasksTable.defaultProps = {
  dateRange: null
};

ProductDetailTasksTable.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.any)
};

export default ProductDetailTasksTable;
