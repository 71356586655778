/* eslint-disable no-unused-expressions */
import React from 'react';
import { Marker } from 'react-mapbox-gl';
import shortId from 'shortid';
import PropTypes from 'prop-types';

export const getCentroidFromArrLatsLongs = data => {
  if (!(data?.length > 0)) {
    return null;
  }
  // data is an array of coordinate pair arrays
  const coordSums = [0, 0];
  data.reduce((acc, [lng, lat]) => {
    acc[0] += lng;
    acc[1] += lat;
    return acc;
  }, coordSums);
  const X = coordSums[0] / data.length;
  const Y = coordSums[1] / data.length;

  if (!isNaN(X) && !isNaN(Y)) {
    return [X, Y];
  }
  return null;
};

export const getArrayOfLabels = geoCollection => {
  const fieldPoints = [];
  const cropZonePoints = [];
  geoCollection?.features?.forEach(item => {
    const coordinateArr = item?.geometry?.coordinates[0];
    if (item?.properties?.crop) {
      const cropCoords = getCentroidFromArrLatsLongs(coordinateArr);
      if (cropCoords) {
        cropZonePoints.push({
          coordinates: cropCoords,
          label: item?.properties?.name ?? 'Untitled-Cropzone'
        });
      }
    } else {
      const fieldCoords = getCentroidFromArrLatsLongs(coordinateArr);
      if (fieldCoords) {
        fieldPoints.push({
          coordinates: fieldCoords,
          label: item?.properties?.name || 'Untitled-Field'
        });
      }
    }
  });
  return { fieldPoints, cropZonePoints };
};

const ObjectMarkerLabel = ({ geoCollection, activeObjectType }) => {
  const labelsArr = getArrayOfLabels(geoCollection);
  let markerPoints = [];
  if (activeObjectType === 'field' || activeObjectType === 'cropzone') {
    markerPoints = labelsArr.cropZonePoints;
  } else {
    markerPoints = labelsArr.fieldPoints;
  }

  return markerPoints
    .filter(
      ({ coordinates }) =>
        Array.isArray(coordinates) && coordinates.length === 2
    )
    .map(({ coordinates, label }) => (
      <Marker
        key={shortId.generate()}
        coordinates={coordinates}
        anchor="bottom"
        style={{
          pointerEvents: 'none'
        }}
      >
        <div className="text-white font-bold text-lg">{label}</div>
      </Marker>
    ));
};

ObjectMarkerLabel.propTypes = {
  geoCollection: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object)
  }),
  activeObjectType: PropTypes.string
};
ObjectMarkerLabel.defaultProps = {
  geoCollection: [],
  activeObjectType: ''
};

export default ObjectMarkerLabel;
