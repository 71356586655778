import React, { useContext, useEffect, useRef, useState } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { season as seasonApi, task as taskApi } from 'utilities/api';
import usePDFTools from 'hooks/usePDFTools';
import { Context } from 'components/Store';
import TaskPdf from 'components/PDF/TaskPdf';
import { sumFieldAcres } from 'components/PDF/helpers/tableGenerator';
import {
  fieldsToTable,
  mapProductsToTable,
  mapWorkerProtectionToTable
} from 'components/PDF/TaskPdf/helpers/tableMaker';
import {
  buildFeature,
  convertFeaturesToMapUri,
  getProductsExtraData,
  getPropertyExtraData
} from 'components/PDF/helpers/pdfTools';
import { condensor } from 'helpers/condensor';
import { truncateCoordinates } from 'helpers/propertyHelpers';

const useTaskEllipseOptions = () => {
  const [{ organization }] = useContext(Context);
  const { downloadPDFV2, downloading, setDownloading } = usePDFTools();
  const [taskToPdf, setTaskToPdf] = useState();
  const [instance, updateInstance] = usePDF({
    document: <TaskPdf task={taskToPdf} />
  });

  const prevPdfUrl = useRef(undefined);

  let _exportLanguage = 'english';

  useEffect(() => {
    if (taskToPdf) {
      updateInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskToPdf]);

  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      prevPdfUrl.current !== instance.url &&
      taskToPdf
    ) {
      prevPdfUrl.current = instance.url;
      downloadPDFV2(prevPdfUrl.current, `Task - ${taskToPdf.name}.pdf`);
      setTaskToPdf(null);
      setDownloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  const exportPdfMain = async taskId => {
    setDownloading(true);
    const { promise } = taskApi.fetch(taskId);
    const { data: task } = await promise.catch(() => ({}));

    if (task) {
      const features = [];
      const mappedPropertiesPromise = task.properties.map(async property => {
        const {
          cropZone: cropZoneInfo,
          field,
          farm,
          crop,
          centroId: { geometry }
        } = await getPropertyExtraData(property.cropZoneId);

        if (cropZoneInfo) {
          features.push(buildFeature(cropZoneInfo.geometry));
        }

        return {
          ...property,
          farmName: farm.name,
          fieldName: field.name,
          cropZoneName: cropZoneInfo.name,
          cropName: crop.name,
          latlong: truncateCoordinates(geometry, 5)?.coordinates
        };
      });

      const mappedProductsPromise = task.products.map(async product => {
        const {
          masterProduct: { registrationnumber },
          activeIngredients,
          reiphisData,
          workerProtection
        } = await getProductsExtraData(product.productId);
        return {
          ...product,
          registrationnumber: registrationnumber
            ? condensor(registrationnumber)
            : '-',
          activeIngredients,
          reiphisData,
          workerProtection
        };
      });

      const { promise: cropSeasonPromise } = seasonApi.fetch(
        task.cropSeasonIds[0].id
      );

      const promises = await Promise.allSettled([
        ...mappedPropertiesPromise,
        ...mappedProductsPromise,
        cropSeasonPromise
      ]);

      const mappedProperties = promises.slice(
        0,
        mappedPropertiesPromise.length
      );
      const mappedProducts = promises.slice(mappedPropertiesPromise.length, -1);
      const cropSeasons = promises.slice(-1);
      const totalFieldsArea = sumFieldAcres(task.properties);
      const generatedDate = new Date();
      const year = format(generatedDate, 'yyyy');
      const fullDate = {
        english: format(generatedDate, 'MM/dd/yyyy h:mm aaa'),
        LATAMSpanish: format(generatedDate, 'dd/MM/yyyy h:mm aaaa')
      };
      const mapImage = convertFeaturesToMapUri(features);
      setTaskToPdf({
        ...task,
        properties: fieldsToTable(
          mappedProperties
            .filter(request => request.status === 'fulfilled')
            .map(request => request.value)
        ),
        organizationName: organization.name,
        cropSeasons: cropSeasons
          .filter(request => request.status === 'fulfilled')
          .map(request => request.value.data.name),
        products: mapProductsToTable({
          products: mappedProducts
            .filter(request => request.status === 'fulfilled')
            .map(request => request.value),
          applicationStrategy: task.applicationStrategy,
          cropNames: mappedProperties
            .filter(request => request.status === 'fulfilled')
            .map(request => request.value)
        }),
        test: 'test',
        _exportLanguage,
        workerProtection: mapWorkerProtectionToTable(
          mappedProducts
            .filter(request => request.status === 'fulfilled')
            .map(request => request.value)
        ),
        totalFieldsArea,
        year,
        fullDate,
        mapImage
      });
    } else {
      setDownloading(false);
    }
  };

  const exportPdfEnglish = taskId => {
    _exportLanguage = 'english';
    exportPdfMain(taskId);
  };

  const exportPdfSpanish = taskId => {
    _exportLanguage = 'LATAMSpanish';
    exportPdfMain(taskId);
  };
  return {
    downloadingPDF: downloading,
    exportPdfEnglish,
    exportPdfSpanish
  };
};

export default useTaskEllipseOptions;
