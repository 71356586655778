import React, { useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, Input } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { Context } from 'components/Store';
import DeleteIcon from 'components/Icons/components/DeleteIcon';
import useFarmData from 'screens/Property/hooks/useFarmData';
import DeleteFarmModal from 'screens/Property/components/DeleteFarmModal';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

import { useFlags } from 'launchdarkly-react-client-sdk';
import DropDownButton from 'components/DropDownButton';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import { RESET_PRELOADED_PAGES } from 'screens/Property/helpers/propertyDataReducer';
import { PanelHeader } from '../components/PanelComponents';
import ArchiveFarmModal from './ArchiveFarm';

const schema = Yup.object().shape({
  farmName: Yup.string().required('Farm Name is required')
});

const EditFarm = ({ farmId, farmName, reloadFarmData, setView }) => {
  const [
    { selectedProperty, isFarmEdited, organization },
    dispatch
  ] = useContext(Context);
  const { editFarm } = useFarmData(farmId, false);

  const [formikErrors, setFormikErrors] = useState({});
  const [deleteFarmModalOpen, setDeleteFarmModalOpen] = useState(false);
  const [archiveFarmModalOpen, setArchiveFarmModalOpen] = useState(false);
  const openDeleteFarmModal = () => setDeleteFarmModalOpen(true);
  const closeDeleteFarmModal = () => setDeleteFarmModalOpen(false);
  const [reRenderProperties, setReRenderProperties] = useState(false);

  const {
    releaseFeArchiveFarmEnable,
    releaseFeArchiveFarmEnableByOrg
  } = useFlags();

  const feArchiveFarm = featureFlagRenderItem(
    releaseFeArchiveFarmEnable,
    releaseFeArchiveFarmEnableByOrg,
    organization.id
  );

  const formValues = { farmName };

  useEffect(() => {
    if (reRenderProperties) {
      dispatch({
        type: RESET_PRELOADED_PAGES
      });
      dispatch({
        type: 'SELECT_PROPERTY',
        payload: {}
      });
      reloadFarmData();

      setReRenderProperties(false);
    }
  }, [reRenderProperties, reloadFarmData, dispatch]);

  const cancel = () => {
    dispatch({
      type: 'SELECT_PROPERTY',
      payload: {
        ...selectedProperty,
        isEditing: false
      }
    });
    reloadFarmData();
  };

  const handleEditFarm = async values => {
    await editFarm({ name: values.farmName });
    dispatch({
      type: 'SELECT_PROPERTY',
      payload: {}
    });
    reloadFarmData();
  };

  const handleCancel = () => {
    dispatch({
      type: 'IS_FARM_EDITED',
      payload: false
    });
  };

  const handleConfirm = () => {
    dispatch({
      type: 'SELECT_PROPERTY',
      payload: {
        ...selectedProperty,
        isEditing: false
      }
    });
    dispatch({
      type: 'IS_FARM_EDITED',
      payload: false
    });

    reloadFarmData();
  };

  return (
    <div className="h-full relative w-full" data-testid="property-edit-farm">
      <PanelHeader
        showTools={false}
        title="Edit Farm"
        showGoBack
        setView={setView}
      />
      <div className="p-4 h-full">
        <Formik
          initialValues={formValues}
          validationSchema={schema}
          onSubmit={handleEditFarm}
        >
          {({ errors, handleSubmit, isSubmitting }) => {
            setFormikErrors(errors);

            return (
              <>
                <ConfirmationModal
                  open={isFarmEdited}
                  handleCancel={() => handleCancel()}
                  handleConfirm={() => handleConfirm()}
                  handleClose={() => handleCancel()}
                >
                  Are you sure you want to leave this page?
                </ConfirmationModal>
                <div className="py-6">
                  <GrowUIFormField
                    control={Input}
                    name="farmName"
                    label="Farm Name*"
                    required
                  />
                </div>
                <div className="absolute bottom-0 left-0 right-0 border-t px-4 py-3 flex justify-between items-center">
                  {feArchiveFarm ? (
                    <DropDownButton
                      title="Archive"
                      onPress={() => setArchiveFarmModalOpen(true)}
                      options={[
                        {
                          label: 'Delete Farm',
                          danger: true,
                          onClick: openDeleteFarmModal
                        }
                      ]}
                    />
                  ) : (
                    <Button
                      danger
                      type="text"
                      icon={<DeleteIcon />}
                      onClick={openDeleteFarmModal}
                    >
                      Delete Farm
                    </Button>
                  )}

                  <div className="w-1/2 flex justify-end">
                    <Button
                      type="text"
                      style={{ marginRight: '0.5rem' }}
                      onClick={cancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      disabled={!!formikErrors.farmName || isSubmitting}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      <DeleteFarmModal
        open={deleteFarmModalOpen}
        close={closeDeleteFarmModal}
        farmName={farmName}
        farmId={farmId}
        setReRenderProperties={setReRenderProperties}
      />
      <ArchiveFarmModal
        farm={{ id: farmId, name: farmName }}
        open={archiveFarmModalOpen}
        onCancel={() => setArchiveFarmModalOpen(false)}
        onConfirm={() => {
          setArchiveFarmModalOpen(false);
          setReRenderProperties(true);
        }}
      />
    </div>
  );
};

EditFarm.propTypes = {
  farmId: PropTypes.string,
  farmName: PropTypes.string,
  reloadFarmData: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired
};

EditFarm.defaultProps = {
  farmId: '',
  farmName: ''
};

export default EditFarm;
