import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import Reducer from 'reducers/reducer';

export const initialState = {
  toolbarComponents: null,
  error: null,
  organizations: null,
  organization: {
    id: process.env.REACT_APP_DEVTEST_ORGANIZATIONID,
    name: 'Test Org'
  },
  permission: [],
  backgroundColor: '#F3F4F6',
  organizationPermissions: [], // an array of (organization,permission) tuples the user can access,
  selectedContext: null, // used to communicate selected context from the Breadcrumb to the active components
  planToConvert: {}, // used to pass plan details to tasks or recommendations
  weatherAutomaticToggle: true, // Toggle feature at global level to populate weather data when "ON"
  shouldResetPropertyName: false, // used to reflect changes of farm/field/cropzone names
  loggedInUserOrgPermission: { role: 'View Only', financialAccess: undefined },
  loadingOrg: true,
  selectedProperty: {},
  expandedPropertyIds: [],
  cropSeasons: [],
  selectedFieldRowId: {},
  isEnableInfoTabEdit: false,
  isMassAssignerActive: false,
  fieldsToMassiveAssign: [],
  massiveChangeSummary: {},
  isFarmEdited: false,
  updateCropName: { cropName: '', cropId: '', shouldUpdate: false },
  massAssignerSuccessCounter: {},
  massAssignerRejections: [],
  isGlobalCropSeasonLoading: false,
  searchTextValueFFT: '',
  isFieldImportedSuccessful: false,
  importedFields: [],
  loadingProperties: true,
  loadTimestamp: new Date().valueOf(),
  isFullScreenNeeded: false,
  uploadedFiles: [],
  loadingCosts: true,
  selectedYieldCommodity: { id: '', name: '' },
  zoomInitialMap: false,
  cropZoneMatcherType: 'null',
  selectedProduct: undefined,
  showToastCropZoneMatcher: false,
  showToastPropertyRemove: false,
  showToastRemoveProduct: false,
  showToastChangesSaved: false,
  toastType: 'null',
  toastMessage: 'null',
  removeProductFailure: false,
  additionalInformationToastCropZoneMatcher: {
    cropZoneName: '',
    operationMapped: '',
    totalOperationMapped: ''
  },
  propertyFilters: {
    showArchivedProperties: false,
    showFarmWithIds: []
  },
  isSidebarOpen: true,
  showToastSaleLocation: false,
  sortRules: undefined,
  selectedLocation: undefined,
  taskCreationFrom: undefined
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, {
    ...initialState
  });

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

Store.defaultProps = {
  children: null
};

Store.propTypes = {
  children: PropTypes.node
};

export const Context = createContext(initialState);
export default Store;
