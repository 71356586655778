import { addMinutes } from 'date-fns';

export const APPLICATION_OPTIONS = [
  { key: 'ByRatePerArea', value: 'Rate/Area' },
  { key: 'ByTotalProduct', value: 'Total Product' },
  { key: 'ByRatePerTank', value: 'Rate/Tank' }
];

export const FETCH_TYPES = {
  paginatedPropertiesMap: 'paginatedPropertiesMap',
  propertiesMap: 'propertiesMap',
  cropSeasonsMap: 'cropSeasonsMap',
  plansMap: 'plansMap'
};

export const DEFAULT_TASK_VALUES = {
  status: 'ready',
  startDate: new Date(new Date().setHours(8, 0, 0, 0)),
  dueDate: addMinutes(new Date().setHours(8, 0, 0, 0), 2 * 60),
  duration: '2',
  type: 'General',
  timingEvent: '',
  // cropSeasonIds: '',
  applicationStrategy: APPLICATION_OPTIONS[0].key,
  flags: [],
  source: [],
  authorizerId: '',
  authorizedDate: new Date(Date.now()),
  bufferZone: '',
  bufferReason: '',
  totalAreaValue: 0,
  tankInformation: {
    tankSizeValue: 0,
    tankSizeUnit: 'bag',
    tankCount: 0,
    carrierPerAreaValue: 0,
    carrierPerAreaUnit: 'bag',
    totalCarrierValue: 0,
    totalCarrierUnit: 'bag',
    tankValueTypeEnteredByUser: 'CarrierPerArea'
  },
  weather: [
    {
      date: '',
      temperature: '',
      temperatureUnit: 'F',
      humidity: '',
      skyCondition: '',
      windSpeed: '',
      windSpeedUnit: 'MPH',
      windDirection: '',
      soilMoisture: 'unknown',
      type: 'start'
    },
    {
      date: '',
      temperature: '',
      temperatureUnit: 'F',
      humidity: '',
      skyCondition: '',
      windSpeed: '',
      windSpeedUnit: 'MPH',
      windDirection: '',
      soilMoisture: 'unknown',
      type: 'end'
    }
  ],
  applicators: [
    {
      personId: '',
      companyId: ''
    }
  ],
  assigneeIds: [
    {
      id: ''
    }
  ],
  notes: '',
  totalApplied: 0,
  coveragePercent: 100,
  properties: [],
  products: [],
  propertiesGroupBy: 'property'
};

export const DUMMY_SEASON = '88888888-8888-8888-8888-888888888888';
