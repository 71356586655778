import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { featureCollection } from '@turf/helpers';
import {
  DragCircleMode,
  DirectMode,
  SimpleSelectMode
} from 'mapbox-gl-draw-circle';
import { isUndefined } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import useTwoWayState from 'hooks/useTwoWayState';
import SelectOnlyMode from './SelectOnlyMode';
import PropertyMap from './PropertyMap';
import useMapData from '../../hooks/useMapData';
import { featuresMatch } from '../../helpers/propertyDataHelpers';
import PropertyMapContext from '../PropertyMapContext';
import { MapEventContext } from './MapEventContext';
import {
  getAllFeatures,
  getGeoJSONCollectionFeatures,
  getFeatureWithParentId,
  getSyncFeaturesForUpdate,
  calculateCentroidByEachProperties
} from '../../helpers/mapApiHelpers';
import {
  LAND_TYPES,
  DEFAULT_ZOOM,
  USA_CENTER_COORDINATES
} from '../../helpers/constants';
import MapEventDisplay from './MapEventDisplay';

const PropertyMapWrapper = ({
  currentCoordinates,
  customStyles,
  displayOnly,
  fields,
  geoJSONCollection,
  liveUpdate,
  onClickShape,
  onFeaturesChange,
  onSelectFeatures,
  parentId,
  selectedFeatureIds,
  setCurrentCoordinates,
  setZoom,
  stretch,
  zoom,
  properties,
  fieldsLoading,
  labelName,
  shapeIdSelected
}) => {
  const {
    selectedShapeIds,
    setSelectedShapeIds,
    setSelectedShapesExternally,
    internalShapeSelectionChanges,
    mapChangeEvent,
    newFeatures,
    addNewMapFeature,
    addNewMapFeatures,
    archiveMapHistoryFeature
  } = useMapData();

  const [markerOn, setMarkerOn] = useState(false);
  const modes = {
    ...MapboxDraw.modes,
    direct_select: DirectMode,
    simple_select: SimpleSelectMode,
    drag_circle: DragCircleMode,
    select_only: SelectOnlyMode
  };

  const defaultMode =
    onSelectFeatures && displayOnly ? 'select_only' : 'simple_select';
  const comparator = useCallback(featuresMatch, []);
  const [
    features,
    setFeatures,
    externalSetFeatures,
    internalFeaturesChanges
  ] = useTwoWayState([], comparator);

  const { location } = useHistory();

  const [mode, setMode] = useState(defaultMode);
  const [viewContext] = useState(LAND_TYPES.FIELD);
  const syncedFeatures = useRef([]);
  let geoJSONCentroid = {};
  let mapCoordinates = [];
  if (location?.state !== null) {
    geoJSONCentroid = calculateCentroidByEachProperties(
      location?.state?.geoJsonData,
      location?.state?.properties
    );

    mapCoordinates = geoJSONCentroid?.geometry?.coordinates;
  }

  if (!currentCoordinates && !mapCoordinates) {
    setCurrentCoordinates(USA_CENTER_COORDINATES);
  }

  const landMapCoordinates =
    currentCoordinates || mapCoordinates || USA_CENTER_COORDINATES;

  // Live update allows changes to be made to api in real time
  useEffect(() => {
    // get featureCollection from geoJSONCollection of from fields
    let changedFeatures = [];
    if (location?.state === null || location?.state === undefined) {
      changedFeatures = geoJSONCollection?.features?.length
        ? getGeoJSONCollectionFeatures(geoJSONCollection)
        : getAllFeatures(fields);
    } else {
      changedFeatures = location?.state?.geoJsonData?.features?.length
        ? getGeoJSONCollectionFeatures(location?.state?.geoJsonData)
        : getAllFeatures(fields);
    }
    if (!isUndefined(changedFeatures)) {
      externalSetFeatures(changedFeatures);
    }
  }, [
    fields,
    geoJSONCollection,
    externalSetFeatures,
    location?.state?.geoJsonData,
    location?.state
  ]);

  // pick up changes to features state, trigger onFeaturesChange, and track synced features
  useEffect(() => {
    if (!isUndefined(internalFeaturesChanges)) {
      onFeaturesChange(internalFeaturesChanges);
    }
  }, [internalFeaturesChanges, onFeaturesChange]);

  // changes to selectedFeatureIds prop are external, update accordingly
  useEffect(() => {
    if (!isUndefined(selectedFeatureIds)) {
      setSelectedShapesExternally(selectedFeatureIds);
    }
  }, [selectedFeatureIds, setSelectedShapesExternally]);

  // notify the parent that selection changes were triggered from the map
  useEffect(() => {
    if (onSelectFeatures && !isUndefined(internalShapeSelectionChanges)) {
      onSelectFeatures(internalShapeSelectionChanges);
    }
  }, [internalShapeSelectionChanges, onSelectFeatures]);

  useEffect(() => {
    if (shapeIdSelected) {
      setSelectedShapeIds([shapeIdSelected]);
    }
  }, [shapeIdSelected, setSelectedShapeIds]);

  const modeReset = () => {
    setMode(defaultMode);
  };

  const handleDrawCreate = (featuresToSave, featureIdsToDelete) => {
    for (let i = 0; i < featuresToSave.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      if (!featuresToSave[i].id) featuresToSave[i].id = uuidv4();
    }
    // if a 2nd arg is provided, that is for features to be removed
    const filterPredicate = featureIdsToDelete
      ? ({ id }) => !featureIdsToDelete.includes(id)
      : () => true;

    setFeatures(previousFeatures => {
      const updatedFeatures = featuresToSave.map(item =>
        getFeatureWithParentId(item, { _id: parentId })
      );
      return previousFeatures.filter(filterPredicate).concat(updatedFeatures);
    });
  };

  const handleDrawUpdate = (shapeId, changedFeatures) => {
    setFeatures(previousFeatures =>
      previousFeatures.map(featureItem => {
        const changedFeature = changedFeatures.find(
          item => item.id === featureItem.id
        );
        return {
          ...featureItem,
          geometry: changedFeature
            ? changedFeature.geometry
            : featureItem.geometry
        };
      })
    );
    syncedFeatures.current = getSyncFeaturesForUpdate(
      syncedFeatures.current,
      shapeId
    );
  };

  const handleDrawDelete = deletedIds => {
    if (!geoJSONCollection.features.length) {
      return;
    }

    setFeatures(previousFeatures =>
      previousFeatures.filter(({ id }) => !deletedIds.includes(id))
    );
  };

  const collection = featureCollection(features);

  return (
    <MapEventContext>
      <PropertyMapContext.Provider
        value={{
          addNewMapFeature,
          addNewMapFeatures,
          archiveMapHistoryFeature,
          onClickShape,
          fields,
          mapChangeEvent,
          markerOn,
          newFeatures,
          selectedShapeIds,
          setMarkerOn,
          setSelectedShapeIds
        }}
      >
        <PropertyMap
          currentCoordinates={landMapCoordinates}
          setCurrentCoordinates={setCurrentCoordinates}
          displayOnly={displayOnly}
          geoJSONCollection={collection}
          customStyles={
            location?.state === null
              ? customStyles
              : {
                  focused: {
                    fillColor: 'rgba(0, 255, 0, 0.15)',
                    fillOutlineColor: 'rgba(255, 255, 255, 1)',
                    fillAntialias: true
                  }
                }
          }
          handleDrawCreate={handleDrawCreate}
          handleDrawUpdate={handleDrawUpdate}
          handleDrawDelete={handleDrawDelete}
          markerOn={markerOn}
          mode={mode}
          modeReset={modeReset}
          setMode={setMode}
          modes={modes}
          liveUpdate={liveUpdate}
          viewContext={viewContext}
          zoom={zoom}
          setZoom={setZoom}
          stretch={stretch}
          properties={properties}
          fieldsLoading={fieldsLoading}
          labelName={location?.label ?? labelName}
        />
      </PropertyMapContext.Provider>
      <MapEventDisplay />
    </MapEventContext>
  );
};

PropertyMapWrapper.defaultProps = {
  currentCoordinates: null,
  customStyles: null,
  displayOnly: false,
  fields: [],
  geoJSONCollection: null,
  liveUpdate: true,
  onClickShape: () => {},
  onFeaturesChange: () => {},
  onSelectFeatures: null,
  parentId: null,
  selectedFeatureIds: undefined,
  setCurrentCoordinates: () => {},
  setZoom: () => {},
  stretch: false,
  zoom: DEFAULT_ZOOM,
  properties: {},
  fieldsLoading: false,
  labelName: '',
  shapeIdSelected: null
};

PropertyMapWrapper.propTypes = {
  currentCoordinates: PropTypes.arrayOf(PropTypes.number),
  customStyles: PropTypes.objectOf(
    PropTypes.shape({
      fillColor: PropTypes.string,
      fillOutlineColor: PropTypes.string,
      fillAntialias: PropTypes.bool
    })
  ),
  displayOnly: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  geoJSONCollection: PropTypes.object,
  liveUpdate: PropTypes.bool,
  onClickShape: PropTypes.func,
  onFeaturesChange: PropTypes.func,
  onSelectFeatures: PropTypes.func,
  parentId: PropTypes.string,
  selectedFeatureIds: PropTypes.arrayOf(PropTypes.string),
  setCurrentCoordinates: PropTypes.func,
  setZoom: PropTypes.func,
  stretch: PropTypes.bool,
  zoom: PropTypes.arrayOf(PropTypes.number),
  properties: PropTypes.arrayOf(PropTypes.object),
  fieldsLoading: PropTypes.bool,
  labelName: PropTypes.string,
  shapeIdSelected: PropTypes.string
};

export default PropertyMapWrapper;
