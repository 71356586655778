import Decimal from 'decimal.js';
import { Maths } from './maths';
import { convertNumberToDesiredFormat } from './transformHelpers';

export default function formatNumber(number, unit, roundedTo = 2) {
  return number != null
    ? `${convertNumberToDesiredFormat(
        Maths.parseFloatRound(number, roundedTo),
        'fixed',
        roundedTo
      )} ${unit}`
    : undefined;
}

export function formatPercentage(percentDecimal) {
  if (!percentDecimal) {
    return undefined;
  }
  return convertNumberToDesiredFormat(
    Maths.parseFloatRound(percentDecimal, 4),
    'percent',
    2
  );
}
export const formatCurrency = amount => {
  if (amount && !Number.isFinite(amount)) {
    try {
      // eslint-disable-next-line no-param-reassign
      amount = new Decimal(amount).toNumber();
    } catch (error) {
      return undefined;
    }
  }
  const { format } = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return format(amount);
};
