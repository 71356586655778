import React, { useContext } from 'react';
import { Button } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Context } from 'components/Store';
import useMassAssigner from 'screens/Property/hooks/useMassAssigner';
import {
  MASS_ASSIGNER_REJECTIONS,
  RESET_MASS_ASSIGNER
} from 'reducers/reducer';
import DeleteCropZoneModalSummaryConflicts from '../PropertyTreeView/components/DeleteCropZoneModalSummaryConflicts';
import CropzoneModalConfirmation from '../PropertyTreeView/components/CropzoneModalConfirmation';

const MassAssignerHeader = ({ reloadData }) => {
  const [
    { massiveChangeSummary, fieldsToMassiveAssign, massAssignerRejections },
    dispatch
  ] = useContext(Context);

  const {
    handleCancel,
    isSaving,
    checkDeleteAction,
    getTotalCropzones,
    openConfirmModal,
    handleComplete,
    setOpenConfirmModal,
    cropZonesToModify
  } = useMassAssigner(
    massiveChangeSummary,
    fieldsToMassiveAssign,
    reloadData,
    dispatch
  );

  const handleModalConfirm = async () => {
    setOpenConfirmModal(false);
    const cropZoneResolved = await handleComplete();

    const mappedCropZones = cropZonesToModify();

    const indexes = cropZoneResolved.reduce((prev, curr, i) => {
      if (curr.status === 'rejected') {
        prev.push(i);
      }

      return prev;
    }, []);

    const conflicts = mappedCropZones.filter(
      (cropZone, i) => indexes.includes(i) && cropZone
    );

    if (conflicts.length > 0 && massiveChangeSummary.action === 'Deleted') {
      dispatch({
        type: MASS_ASSIGNER_REJECTIONS,
        payload: conflicts
      });
      dispatch({
        type: RESET_MASS_ASSIGNER,
        payload: true
      });
    }
  };

  const seasons = [];

  fieldsToMassiveAssign.forEach(field =>
    field.cropzones.forEach(({ cropSeasons }) =>
      cropSeasons.forEach(season => seasons.push(season))
    )
  );

  return (
    <div
      className="h-13 px-8 pt-2 bg-gray-100 flex justify-between items-center"
      id="mass-assigner-header"
      data-testid="mass-assigner-header"
    >
      <div className="h-full flex">
        <div className="flex flex-col justify-between">
          <span className="text-gray-800 font-body">Selected Fields</span>
          <span className="text-3xl font-body">
            {fieldsToMassiveAssign.length}
          </span>
        </div>
        <div className="flex flex-col justify-between ml-12">
          <span className="text-gray-800 font-body">Selected Crop Zones</span>
          <span className="text-3xl font-body">{getTotalCropzones()}</span>
        </div>
      </div>
      <div className="flex">
        <Button
          type="outline"
          size="lg"
          onClick={handleCancel}
          disabled={isSaving}
        >
          Cancel
        </Button>
        <div className="ml-5">
          <Button
            type="primary"
            size="lg"
            onClick={checkDeleteAction}
            disabled={
              !massiveChangeSummary.fieldsToMassiveAssign?.length || isSaving
            }
            loading={isSaving}
          >
            Complete
          </Button>
        </div>
      </div>
      <DeleteCropZoneModalSummaryConflicts
        open={!!massAssignerRejections.length}
        onClose={() => {
          dispatch({
            type: MASS_ASSIGNER_REJECTIONS,
            payload: []
          });
        }}
        cropzonesToDelete={massAssignerRejections}
      />
      <CropzoneModalConfirmation
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={handleModalConfirm}
        relatedSeasons={_.uniqBy(seasons, 'name')}
        action={massiveChangeSummary.action}
      />
    </div>
  );
};

MassAssignerHeader.propTypes = {
  reloadData: PropTypes.func.isRequired
};

export default MassAssignerHeader;
