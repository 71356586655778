import { useCallback, useContext, useState } from 'react';
import { integrations as integrationsApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';

const useIntegrationMatching = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(Context);
  const orgId = localStorage.getItem('selectedOrganizationId');

  const getMatches = useCallback(
    ({ vendor, matchTypes = [] }) => {
      const { promise } = integrationsApi
        .createChildApi({
          action: `integrations/${vendor}/match`
        })
        .fetch(
          null,
          {
            types: matchTypes.join(',')
          },
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const createMatchingProducts = useCallback(
    ({ vendor, products }) => {
      const { promise } = integrationsApi
        .createChildApi({
          action: `integrations/${vendor}/match/products`
        })
        .create(products, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const createMatchingProductsV2 = useCallback(
    ({ vendor, products }) => {
      const { promise } = integrationsApi
        .createChildApi({
          action: `integrations/${vendor}/match/products/v2`
        })
        .create(products, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(e => e)
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const createMatches = useCallback(
    ({ vendor, matches }) => {
      const { promise } = integrationsApi
        .createChildApi({
          action: `integrations/${vendor}/match/`
        })
        .create(matches, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  return {
    getMatches,
    createMatches,
    createMatchingProducts,
    createMatchingProductsV2,
    loading
  };
};

export default useIntegrationMatching;
