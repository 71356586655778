import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MatchArrowIcon from 'screens/Integrations/PropertiesMatch/components/FarmList/components/icons/MatchArrowIcon';
import VendorPropertyMapView from 'screens/Integrations/PropertiesMatch/components/PropertyView/components/VendorPropertyMapView';
import { ChevronDown, Select } from 'syngenta-digital-cropwise-react-ui-kit';
import SearchIcon from 'assets/search.svg';
import FieldMapView from 'components/FieldMapView';
import { fieldToFeature } from 'screens/Property/components/FieldImporterModal/fieldImporterHelper';
import { PropertiesMatchContext } from 'screens/Integrations/PropertiesMatch/context/PropertiesMatchProvider';
import { strForSearch } from '../../../../helpers';

import './antd-override.css';

const PropertyContent = ({ property, fftSelectGroups, matched }) => {
  const { addPropertyMatchIdWithVendorIdInControl } = useContext(
    PropertiesMatchContext
  );

  const onChangeCwfProperty = cwfCropZoneId => {
    addPropertyMatchIdWithVendorIdInControl(cwfCropZoneId, property.id);
  };

  return (
    <div className="justify-self-start pr-15 col-span-w w-full">
      <div className="grid items-center grid-cols-3">
        <div className="text-sm col-span-1" style={{ position: 'relative' }}>
          <div className="mt-2 bg-gray-100 rounded-lg overflow-hidden text-left p-4">
            <VendorPropertyMapView vendorId={property?.id} />
            {/*
              We will evaluate this functionality of this button yet.
              <div className="mt-3">
                <Switch
                  size="small"
                  checked={property.hide}
                  onChange={() => {
                    setFieldHiddenInRecommendations(property.id);
                  }}
                />
                  <span className="ml-2">Hide field from import</span>
                </div>
            */}
          </div>
        </div>
        <div className="flex items-center justify-center h-full col-span-1">
          <span className="mb-1">
            <MatchArrowIcon />
          </span>
        </div>
        <div className="flex flex-col col-span-1">
          <div
            className="text-neutral-600 text-left mb-1 text-sm"
            data-testid="cropzones-heading"
          >
            Cropwise Crop Zones
          </div>
          <div className="flex items-center justify-center">
            {fftSelectGroups?.length > 0 && (
              <div className="relative inline-block w-full cropzone-dropdown">
                <div className="prefix-icon">
                  <img alt="Search icon" src={SearchIcon} />
                </div>
                <Select
                  disabled={property.hide}
                  size="middle"
                  onChange={value => {
                    onChangeCwfProperty(value);
                  }}
                  filterOption={(input, option) => {
                    return strForSearch(option.title)?.includes(
                      strForSearch(input)
                    );
                  }}
                  showSearch
                  suffixIcon={<ChevronDown className="dark-blue" />}
                  placeholder="Search by crop zone, field, or farm name"
                  optionLabelProp="label"
                  data-testid="select-cwf-cropzone"
                  options={fftSelectGroups}
                  defaultValue={
                    matched
                      ? {
                          label: matched.name,
                          value: matched.id
                        }
                      : null
                  }
                />
              </div>
            )}
          </div>
          {matched && (
            <div className="mt-2 bg-gray-100 rounded-lg overflow-hidden w-67 p-4">
              <FieldMapView
                key="property-preview"
                className="rounded-lg"
                feature={fieldToFeature(matched)}
                area={matched?.reportedArea || matched?.boundaryArea}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PropertyContent.defaultProps = {
  fftSelectGroups: [],
  property: {},
  matched: undefined
};

PropertyContent.propTypes = {
  matched: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    reportedArea: PropTypes.number,
    boundaryArea: PropTypes.number
  }),
  fftSelectGroups: PropTypes.arrayOf(PropTypes.object),
  property: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    hide: PropTypes.bool
  })
};

export default PropertyContent;
