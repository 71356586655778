import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { MapContext, GeoJSONLayer } from 'react-mapbox-gl';
import { center, points } from '@turf/turf';
import { featureCollection } from '@turf/helpers';
import { Context } from 'components/Store';
import { getUpdatedBoundingBox } from 'screens/Property/helpers/propertyDataHelpers';
import { LAST_COORDINATES_STORED } from 'screens/Property/PropertiesLanding/helpers/constants';

import { useLocation } from 'react-router-dom';
import PropertyMapContext from '../../PropertyMapContext';

const SELECTED_LAYER_TYPE = 'selected';
const FOCUSED_LAYER_TYPE = 'focused';

const buildCustomLayers = (geoJSONArr, stylesArr, onClickShape) => {
  return geoJSONArr.map((geoJSON, idx) => {
    const { fillColor, fillOutlineColor, fillAntialias } = stylesArr[idx] || {};
    const fc = featureCollection(geoJSON);
    return (
      <GeoJSONLayer
        data={fc}
        fillOnClick={onClickShape}
        fillPaint={{
          'fill-color': fillColor || 'rgba(255, 255, 255, 0.15)',
          'fill-outline-color': fillOutlineColor || 'rgba(255, 255, 255, 1)',
          'fill-antialias': fillAntialias || true
        }}
      />
    );
  });
};

const DynamicLayer = ({
  geoJSON,
  onClickShape,
  labelKey,
  isGeoJSONLabelEnabled
}) => {
  if (!isGeoJSONLabelEnabled) {
    return (
      <div data-testid="geojson-layer">
        <GeoJSONLayer
          fillOnClick={onClickShape}
          data={geoJSON}
          fillPaint={{
            'fill-color': [
              'case',
              ['==', ['get', '$layer'], SELECTED_LAYER_TYPE],
              'rgba(49, 180, 242, 0.05)',
              ['==', ['get', '$layer'], FOCUSED_LAYER_TYPE],
              'rgba(255, 255, 255, 0.15)',
              'rgba(255, 255, 255, 0.15)'
            ],
            'fill-antialias': true
          }}
          linePaint={{
            'line-color': 'rgba(255, 255, 255, 1)',
            'line-width': 3
          }}
        />
      </div>
    );
  }

  return (
    <div data-testid="geojson-layer-with-labels">
      <GeoJSONLayer
        fillOnClick={onClickShape}
        data={geoJSON}
        fillPaint={{
          'fill-color': [
            'case',
            ['==', ['get', '$layer'], SELECTED_LAYER_TYPE],
            'rgba(49, 180, 242, 0.05)',
            ['==', ['get', '$layer'], FOCUSED_LAYER_TYPE],
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0.3)'
          ],
          'fill-antialias': true
        }}
        linePaint={{
          'line-color': 'rgba(255, 255, 255, 1)',
          'line-width': 2
        }}
        symbolLayout={{
          'text-field': ['get', labelKey],
          'text-anchor': 'center',
          'text-justify': 'auto',
          'text-variable-anchor': ['left'],
          'text-radial-offset': 0,
          'text-size': 20,
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'symbol-z-order': 'source',
          'text-allow-overlap': false
        }}
        symbolPaint={{
          'text-color': 'white'
        }}
      />
    </div>
  );
};

const SelectedLayer = ({ geoJSON, onClickShape }) => (
  <GeoJSONLayer
    fillOnClick={onClickShape}
    data={geoJSON}
    fillPaint={{
      'fill-color': 'rgba(49, 180, 242, 0.37)',
      'fill-outline-color': 'rgba(49, 180, 242, 1)',
      'fill-antialias': true
    }}
  />
);

const PropertyFeatureLayer = ({
  geoJSON,
  customStyles,
  visible,
  setZoom,
  activeObjectType,
  isGeoJSONLabelEnabled
}) => {
  const { pathname } = useLocation();
  const [boundingBox, setBoundingBox] = useState();
  const [mapIsReady, setMapIsReady] = useState(false);
  const [boxIsMoving, setBoxIsMoving] = useState(false);
  const [{ selectedProperty, loadingProperties, loadTimestamp }] = useContext(
    Context
  );

  const isAnimateActive = useRef(true);
  const selectedJSON = useMemo(
    () =>
      featureCollection(
        geoJSON?.features?.filter(
          ({ properties }) => properties.$layer === SELECTED_LAYER_TYPE
        ) || []
      ),
    [geoJSON]
  );

  const customJSONArr = useMemo(
    () =>
      Object.keys(customStyles || {}).map(key =>
        (geoJSON.features || []).filter(
          ({ properties }) => properties.$layer === key
        )
      ),
    [geoJSON, customStyles]
  );
  const map = useContext(MapContext);

  if (!mapIsReady && map && !map.isMoving() && map.isStyleLoaded()) {
    setMapIsReady(true);
  }

  useEffect(() => {
    if (selectedProperty.id) {
      isAnimateActive.current = true;
    }
  }, [selectedProperty]);

  // adjust the bounding box so that the current zoom level can be determined
  useEffect(() => {
    // focused features are similar to selected but the map also focuses in on them
    const focusedGeoJSONFeatures = geoJSON?.features.filter(
      feature => feature?.properties?.$layer === FOCUSED_LAYER_TYPE
    );

    const fc = focusedGeoJSONFeatures.length
      ? featureCollection(focusedGeoJSONFeatures)
      : geoJSON;

    const newBounds = getUpdatedBoundingBox(
      boundingBox,
      fc,
      focusedGeoJSONFeatures.length
    );
    if (fc && newBounds && mapIsReady && !boxIsMoving) {
      if (newBounds.find(bound => bound === Infinity || bound === -Infinity)) {
        return;
      }
      setTimeout(() => {
        const firstPart = newBounds.slice(0, 2);
        const secondPart = newBounds.slice(-2);

        const feature = points([firstPart, secondPart]);
        const centroid = center(feature);

        localStorage.setItem(
          LAST_COORDINATES_STORED,
          JSON.stringify(centroid.geometry.coordinates)
        );

        map.fitBounds(newBounds, {
          padding: 160,
          animate: isAnimateActive.current
        });
        setBoundingBox(newBounds);
      }, 1);
      setBoxIsMoving(true);
    }
  }, [boundingBox, geoJSON, map, boxIsMoving, mapIsReady]);

  useEffect(() => {
    if (loadingProperties) {
      setBoundingBox(undefined);
    }
  }, [loadingProperties, loadTimestamp]);

  // wait for the bounding box movement to stop then set zoom to the map's current zoom value
  useEffect(() => {
    let mounted = true;
    const moveChecker = () => {
      if (mounted) {
        setZoom(() => {
          const newZoom = map.getZoom();
          return [newZoom];
        });
        setBoxIsMoving(false);
      }
    };
    if (pathname === '/app/property') {
      if (map) {
        map.on('moveend', moveChecker);
      }
    }
    if (pathname !== '/app/property') {
      if (map && boxIsMoving) {
        map.on('moveend', moveChecker);
      }
    }

    return () => {
      mounted = false;
      if (map) {
        map.off('moveend', moveChecker);
      }
    };
  }, [boxIsMoving, map, setZoom, pathname]);

  const labelKey =
    activeObjectType === 'field' || activeObjectType === 'cropzone'
      ? 'fieldAndCropZoneLabel'
      : 'defaultLabel';

  return geoJSON && visible ? (
    <PropertyMapContext.Consumer>
      {({ onClickShape }) => (
        <>
          <SelectedLayer onClickShape={onClickShape} geoJSON={selectedJSON} />
          <DynamicLayer
            onClickShape={onClickShape}
            geoJSON={geoJSON}
            labelKey={labelKey}
            isGeoJSONLabelEnabled={isGeoJSONLabelEnabled}
          />
          {customStyles &&
            buildCustomLayers(
              customJSONArr,
              Object.values(customStyles),
              onClickShape
            )}
        </>
      )}
    </PropertyMapContext.Consumer>
  ) : null;
};

SelectedLayer.propTypes = {
  geoJSON: PropTypes.objectOf.isRequired,
  onClickShape: PropTypes.func.isRequired
};

PropertyFeatureLayer.propTypes = {
  geoJSON: PropTypes.objectOf,
  customStyles: PropTypes.objectOf(
    PropTypes.shape({
      fillColor: PropTypes.string,
      fillOutlineColor: PropTypes.string,
      fillAntialias: PropTypes.bool
    })
  ),
  visible: PropTypes.bool,
  setZoom: PropTypes.func,
  activeObjectType: PropTypes.string.isRequired,
  isGeoJSONLabelEnabled: PropTypes.bool.isRequired
};

PropertyFeatureLayer.defaultProps = {
  geoJSON: null,
  customStyles: null,
  visible: false,
  setZoom: () => {}
};

DynamicLayer.propTypes = {
  geoJSON: PropTypes.objectOf.isRequired,
  onClickShape: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  isGeoJSONLabelEnabled: PropTypes.bool.isRequired
};

export default PropertyFeatureLayer;
