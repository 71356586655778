import { useCallback, useRef, useEffect, useState } from 'react';
import catchCancel from 'helpers/catchCancel';
import { inventoryProduct as inventoryProductApi } from 'utilities/api';

const useProductApplicationData = (productId, defaultParams) => {
  const requestNumber = useRef(0);
  const [tasks, setTasks] = useState([]);
  const [tasksError, setTasksError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [request, setRequest] = useState({
    pageNo,
    size: -1,
    ...defaultParams
  });
  const getProductApplications = useCallback(
    async params => {
      setIsLoading(true);
      requestNumber.current += 1;
      const config = {
        ...request,
        ...params,
        sortBy: undefined,
        sortDir: undefined,
        pageNo: undefined
      };
      const { promise } = inventoryProductApi.fetch(
        `${productId}/applications`,
        config
      );
      promise
        .then(res => {
          const apiTasks = res.data.results;
          setTasks(apiTasks);
        })
        .catch(err => {
          setTasksError(err);
        })
        .catch(catchCancel)
        .finally(() => setIsLoading(false));
    },
    [request, setIsLoading, productId]
  );

  const reload = useCallback(async () => {
    getProductApplications();
  }, [getProductApplications]);

  const reset = () => {
    setRequest({
      ...request,
      pageNo,
      totalPages: 0
    });
  };

  useEffect(() => {
    reload();
  }, [reload, request]);

  const goToPage = useCallback(
    (newPage, params) => {
      setPageNo(newPage);
      setRequest(r => ({ ...r, ...params, pageNo: newPage }));
    },
    [setRequest]
  );

  const sort = useCallback(
    (field, dir) => {
      setRequest(r => ({ ...r, pageNo: 0, sortBy: field, sortDir: dir }));
    },
    [setRequest]
  );

  return {
    getProductApplications,
    tasks,
    tasksError,
    isLoading,
    pageNo: request.pageNo,
    goToPage,
    reset,
    reload,
    sort,
    sortBy: request.sortBy,
    sortDir: request.sortDir
  };
};

export default useProductApplicationData;
