import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ClickableDiv from 'components/ClickableDiv';
import ChevronDown from 'assets/chevron_down.svg';
import ChevronUp from 'assets/chevron_up.svg';
import { Button } from '@agconnections/grow-ui';
import { Input } from 'syngenta-digital-cropwise-react-ui-kit';
import SearchIcon from 'components/Icons/SearchIcon';

import './index.css';
import useDebounce from 'hooks/useDebounce';

const Loading = () => (
  <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-500" />
  </div>
);

const ItemsDropdown = ({
  valueRenderer,
  onChange,
  onFilter,
  isServerSideFilter,
  value,
  items,
  ItemComponent,
  bottomButtonText,
  onBottomButtonClick,
  bottomButtonTextColor,
  id,
  rightOffset,
  heightClass,
  mainClass,
  isInvalid,
  isLoading,
  mergeSearchInputWithValue,
  searchPlaceholderText,
  itemOptionClassName
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState(undefined);
  const debouncedSearchTerm = useDebounce(searchText, 300);
  const [filteredItems, setFilteredItems] = useState(items);

  const dropdownRef = useRef(null);
  const mergeInputRef = useRef(null);

  const openDiv = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (items && items.length > 0) {
      setFilteredItems(items);
    }
  }, [items]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setSearchText(undefined);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      onFilter &&
      typeof onFilter === 'function' &&
      debouncedSearchTerm !== undefined
    ) {
      if (isServerSideFilter) {
        return onFilter('empty', debouncedSearchTerm);
      }
      const newFilteredItems = [...items].reduce((acc, cur) => {
        if (!cur.items) {
          if (onFilter(cur, debouncedSearchTerm)) {
            return acc.concat([cur]);
          }
        }

        if (cur.items) {
          const filteredSeries = cur.items.filter(seriesItem =>
            onFilter(seriesItem, debouncedSearchTerm)
          );
          if (filteredSeries.length === 0) {
            return acc;
          }
          return acc.concat([{ ...cur, items: filteredSeries }]);
        }
        return acc;
      }, []);

      return setFilteredItems([...newFilteredItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, onFilter]);

  const handleFilter = searchValue => {
    setSearchText(searchValue);
  };

  const handleSearchFocus = () => {
    if (!isDropdownOpen) {
      openDiv();
    }
  };

  const handleMergedChange = searchValue => {
    setSearchText(searchValue);
    handleFilter(searchValue);
  };

  const renderMergedInputValue = () => {
    if (searchText !== undefined && searchText !== null) {
      return searchText;
    }

    if (value) {
      return valueRenderer(value);
    }

    return '';
  };

  const renderSuffixIcon = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (isDropdownOpen) {
      return <img className="mr-1" src={ChevronUp} alt="Sort Icon" />;
    }
    return <img className="mr-1" src={ChevronDown} alt="Sort Icon" />;
  };

  const getSelectionClass = itemValue =>
    JSON.stringify(itemValue) === JSON.stringify(value)
      ? 'bg-selectedBlue'
      : 'hover:bg-neutral-100';

  const selectedValueComponent = value ? valueRenderer(value) : undefined;
  const renderItem = item => {
    const { value: itemValue } = item;
    if (item?.onItemClick) {
      return (
        <ClickableDiv
          className="h-8 gr-whitespace-nowrap"
          onClick={() => {
            setIsDropdownOpen(false);
            setSearchText('');
            item.onItemClick();
          }}
          key={item.key || JSON.stringify(item.value)}
        >
          {ItemComponent ? <ItemComponent item={item} /> : item.value}
        </ClickableDiv>
      );
    }
    if (itemValue) {
      return (
        <ClickableDiv
          key={item.key || JSON.stringify(itemValue)}
          className={`${getSelectionClass(itemValue)} ${
            ItemComponent ? '' : 'h-6'
          } ${itemOptionClassName}`}
          onClick={() => {
            setIsDropdownOpen(false);
            setSearchText(undefined);
            onChange(itemValue);
          }}
        >
          {ItemComponent ? <ItemComponent item={item} /> : <>{item.label}</>}
        </ClickableDiv>
      );
    }

    if (item.items) {
      return (
        <>
          {ItemComponent ? <ItemComponent item={item} /> : item.label}
          {item.items.map(it => renderItem(it))}
        </>
      );
    }

    return <ItemComponent item={item} /> || <>{item.label}</>;
  };
  return (
    <div
      className={`w-full mt-1 ${mainClass}`}
      ref={dropdownRef}
      id={id}
      data-testid="items-drop-down-field"
    >
      {!mergeSearchInputWithValue ? (
        <Button
          onClick={openDiv}
          style={{ width: '100%' }}
          type="outline"
          ghost={!isInvalid}
          danger={isInvalid}
          disabled={isLoading}
          dataTestId={id}
        >
          <div className="flex flex-row w-full relative">
            <div
              style={{ whiteSpace: 'nowrap' }}
              className="flex-1 overflow-x-hidden whitespace-nowrap"
            >
              {selectedValueComponent}
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <img className="mr-1" src={ChevronDown} alt="Sort Icon" />
            )}
          </div>
        </Button>
      ) : (
        <div className="flex flex-row">
          <div
            style={{ whiteSpace: 'nowrap' }}
            className="flex-1 overflow-x-hidden whitespace-nowrap"
          >
            <Input
              id="label-search-input"
              data-testid="merged-input-items-dropdown"
              placeholder={searchPlaceholderText}
              maxLength="20"
              onChange={e => handleMergedChange(e.target.value)}
              onFocus={() => handleSearchFocus()}
              value={renderMergedInputValue()}
              prefix={<SearchIcon />}
              ref={mergeInputRef}
              suffix={renderSuffixIcon()}
            />
          </div>
        </div>
      )}

      {isDropdownOpen && (
        <div
          className="absolute bg-white z-50 mt-2 shadow-card rounded"
          style={{
            width: `calc(${dropdownRef.current.clientWidth}px - ${rightOffset}px)`
          }}
          data-testid="items-drop-down-options"
        >
          {!mergeSearchInputWithValue &&
            onFilter &&
            typeof onFilter === 'function' && (
              <div className="border-b-1 p-3 base-drop-down-search-input">
                <Input
                  className="bg-gray-400"
                  id="label-search-input"
                  placeholder={searchPlaceholderText}
                  maxLength="20"
                  onChange={e => handleFilter(e.target.value)}
                  suffix={<SearchIcon />}
                />
              </div>
            )}
          <div className={`${heightClass}`}>
            <div className="w-full h-full flex flex-col justify-center">
              <div className="mt-2 flex-1 overflow-x-hidden overflow-y-auto w-full">
                {filteredItems.map(item => renderItem(item))}
              </div>
              {bottomButtonText && onBottomButtonClick && (
                <div className="flex justify-center border-t border-solid border-neutral-100">
                  <div className="mx-4 my-2 w-full h-full">
                    <Button
                      onClick={() => {
                        setIsDropdownOpen(false);
                        onBottomButtonClick();
                      }}
                      style={{ width: '100%', color: bottomButtonTextColor }}
                      type="outline"
                      ghost={!isInvalid}
                      danger={isInvalid}
                      dataTestId="item-drop-bottom-button"
                    >
                      {bottomButtonText}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ItemsDropdown.defaultProps = {
  value: null,
  id: '',
  isInvalid: false,
  rightOffset: 24,
  heightClass: 'h-100',
  mainClass: 'items-drop-down-field',
  isLoading: false,
  isServerSideFilter: false,
  bottomButtonText: null,
  onBottomButtonClick: null,
  bottomButtonTextColor: '',
  ItemComponent: null,
  onFilter: undefined,
  mergeSearchInputWithValue: false,
  searchPlaceholderText: 'Search...',
  itemOptionClassName: 'gr-whitespace-nowrap'
};

ItemsDropdown.propTypes = {
  valueRenderer: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  ItemComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    PropTypes.string
  ]),
  heightClass: PropTypes.string,
  mainClass: PropTypes.string,
  rightOffset: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
  isServerSideFilter: PropTypes.bool,
  onBottomButtonClick: PropTypes.func,
  bottomButtonText: PropTypes.string,
  bottomButtonTextColor: PropTypes.string,
  id: PropTypes.string,
  isInvalid: PropTypes.bool,
  isLoading: PropTypes.bool,
  searchPlaceholderText: PropTypes.string,
  mergeSearchInputWithValue: PropTypes.bool,
  itemOptionClassName: PropTypes.string
};

export default ItemsDropdown;

ItemsDropdown.ClickableElement = ({ children }) => children;
