import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import _ from 'lodash';
import { Context } from 'components/Store';
import { findGeoJSON } from 'screens/Property/helpers/propertyDataHelpers';
import useMassAssignerTools from 'screens/Property/hooks/useMassAssignerTools';

import AddCropZonesModal from '../AddCropZonesModal';
import FarmRow from '../../../FarmRow';
import FieldRow from '../../../FieldRow';
import CropZoneRow from '../../../CropZoneRow';
import MassAssignerActions from '../MassAssignerActions';

const MassAssignerTree = ({
  properties,
  fieldsAndAreasGeoJSONCollection,
  propertiesFilter
}) => {
  const [{ fieldsToMassiveAssign, searchTextValueFFT }, dispatch] = useContext(
    Context
  );
  const {
    handleSelectedFarm,
    handleSelectedField,
    isCropzoneSelected,
    handleSelectedCropzone,
    handleSelectAllProperties,
    selectedAllProperties,
    localProperties
  } = useMassAssignerTools(properties, fieldsToMassiveAssign, dispatch);
  const [openAddCropZonesModal, setOpenAddCropZonesModal] = useState(false);

  return (
    <div
      data-tree
      className="relative h-full flex-grow flex flex-col"
      data-testid="mass-assigner-tree"
    >
      <div className=" px-1 h-full overflow-y-auto">
        <Table>
          <Table.Row
            onClick={handleSelectAllProperties}
            selectable
            selected={selectedAllProperties}
          >
            <Table.Cell>All Properties</Table.Cell>
          </Table.Row>
          {propertiesFilter(localProperties, searchTextValueFFT).map(
            property => {
              return (
                <Table.RowGroup
                  arrowIcon
                  onClick={() => handleSelectedFarm(property)}
                  selectable={!!property.fields.length}
                  selected={property.selected}
                >
                  <Table.Row>
                    <Table.Cell>
                      <FarmRow farm={property} />
                    </Table.Cell>
                  </Table.Row>
                  {property.fields.map(field => {
                    const fieldGeoJSON = findGeoJSON(
                      fieldsAndAreasGeoJSONCollection,
                      field
                    );

                    return (
                      <Table.RowGroup
                        arrowIcon
                        onClick={() => handleSelectedField(property, field)}
                        selectable={!!property.fields.length}
                        selected={
                          _.findIndex(fieldsToMassiveAssign, field) >= 0
                        }
                      >
                        <Table.Row>
                          <Table.Cell>
                            <FieldRow field={field} icon={fieldGeoJSON} />
                          </Table.Cell>
                        </Table.Row>
                        {field.cropzones.map(cropzone => {
                          const cropzoneGeoJSON = findGeoJSON(
                            fieldsAndAreasGeoJSONCollection,
                            cropzone
                          );

                          return (
                            <Table.Row
                              onClick={() =>
                                handleSelectedCropzone(
                                  property,
                                  field,
                                  cropzone
                                )
                              }
                              selectable={!!property.fields.length}
                              selected={isCropzoneSelected(cropzone)}
                            >
                              <Table.Cell>
                                <CropZoneRow
                                  cropZone={cropzone}
                                  icon={cropzoneGeoJSON}
                                  fieldId={field.id}
                                  isEditable={false}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.RowGroup>
                    );
                  })}
                </Table.RowGroup>
              );
            }
          )}
        </Table>
      </div>
      <MassAssignerActions
        dispatch={dispatch}
        fieldsToMassiveAssign={fieldsToMassiveAssign}
        setOpenAddCropZonesModal={setOpenAddCropZonesModal}
      />
      {openAddCropZonesModal && (
        <AddCropZonesModal
          open={openAddCropZonesModal}
          onClose={() => setOpenAddCropZonesModal(false)}
        />
      )}
    </div>
  );
};

MassAssignerTree.defaultProps = {
  properties: [],
  fieldsAndAreasGeoJSONCollection: {}
};

MassAssignerTree.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  fieldsAndAreasGeoJSONCollection: PropTypes.objectOf(PropTypes.any),
  propertiesFilter: PropTypes.func.isRequired
};

export default MassAssignerTree;
