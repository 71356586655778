import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import { getStringParsed } from 'utilities/helpers';
import PropertyMapWrapper from './PropertyMapWrapper';
import { calculateCentroidByEachProperties } from '../../helpers/mapApiHelpers';
import { LAST_COORDINATES_STORED } from '../helpers/constants';
import PropertyLandingContext from '../context';

const PropertyLandingMap = ({ properties, onSetShowSummaryPanel }) => {
  const {
    selectedProperty,
    selectedField,
    propertyZoom,
    onPropertySetZoom,
    isLoadingFields,
    fieldsAndAreasGeoJSONCollection: geoJSONCollection,
    onPropertySelection
  } = useContext(PropertyLandingContext);

  const [manualCoords, setManualCoords] = useState(
    getStringParsed(localStorage.getItem(LAST_COORDINATES_STORED))
  );

  const geoJSONCentroid = useMemo(() => {
    return JSON.stringify(
      calculateCentroidByEachProperties(geoJSONCollection, properties)
    );
  }, [geoJSONCollection, properties]);

  const mapCoordinates = useMemo(
    () => manualCoords || JSON.parse(geoJSONCentroid)?.geometry?.coordinates,
    [geoJSONCentroid, manualCoords]
  );

  useEffect(() => {
    if (selectedField?.length > 0) {
      onSetShowSummaryPanel(true)();
    }
  }, [selectedField, onSetShowSummaryPanel]);

  const onClickShape = useCallback(
    event => {
      let fldId;
      const { $landType, ...featureProperties } = event.features[0].properties;
      if ($landType === 'field') fldId = featureProperties.id;
      else if ($landType === 'cropzone')
        fldId = featureProperties.$parentFieldId;
      if (fldId) {
        onSetShowSummaryPanel(true)();
        onPropertySelection({
          ...featureProperties,
          fromMap: true
        });
      }
    },
    [onSetShowSummaryPanel, onPropertySelection]
  );

  const labelName = (selectedProperty?.type || 'org')
    .toLowerCase()
    .replace(/\s+/g, '');

  return (
    <div className="relative w-full h-full">
      <PropertyMapWrapper
        fieldsLoading={isLoadingFields}
        zoom={propertyZoom}
        setZoom={onPropertySetZoom}
        currentCoordinates={mapCoordinates}
        setCurrentCoordinates={setManualCoords}
        displayOnly
        geoJSONCollection={geoJSONCollection}
        onClickShape={onClickShape}
        labelName={labelName}
        selectedField={selectedField}
      />
    </div>
  );
};

PropertyLandingMap.defaultProps = {
  properties: null,
  onSetShowSummaryPanel: () => () => {}
};

PropertyLandingMap.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  onSetShowSummaryPanel: PropTypes.func
};

export default PropertyLandingMap;
