import { useCallback, useContext, useState } from 'react';
import { agrian as agrianApi } from 'utilities/api';
import axios from 'axios';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { createServerError, parseServerError } from 'helpers/errorHelpers';
import { useHistory } from 'react-router-dom';
import { CROPWISE_PROXY_V2_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';
import { useToast } from '../components/ToastContainer';
import { paths } from '../routes/paths';

const useAgrianApi = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(Context);
  const orgId = localStorage.getItem('selectedOrganizationId');
  const toast = useToast();
  const history = useHistory();

  const authenticate = useCallback(
    ({ email, password }) => {
      const { promise } = agrianApi
        .createChildApi({
          action: 'integrations/agrian/auth'
        })
        .create(
          { email, password },
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const growers = useCallback(
    (hideError = false) => {
      const { promise } = agrianApi
        .createChildApi({
          action: 'integrations/agrian/growers'
        })
        .fetch(null, undefined, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(e => {
          if (!hideError) {
            parseServerError(dispatch)(e);
          }
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const saveOrganizationMatch = useCallback(
    ({ growerId, organizationId, growerDescription }) => {
      const { promise } = agrianApi
        .createChildApi({
          action: 'integrations/agrian/match'
        })
        .create(
          [
            {
              cwfEntityId: orgId,
              agrianEntityDescription: growerDescription,
              agrianEntityId: growerId,
              type: 'Grower'
            },
            {
              cwfEntityId: orgId,
              agrianEntityDescription: growerDescription,
              agrianEntityId: organizationId,
              type: 'Organization'
            }
          ],
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getAgrianRecommendationsBySeason = useCallback(
    params => {
      const { promise } = agrianApi
        .createChildApi({
          action: 'integrations/agrian/recommendations'
        })
        .fetch(null, params, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });
      setLoading(true);

      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const getAgrianRecommendationsBySeasonWithoutCache = useCallback(
    params => {
      const promise = axios.get(
        `${CROPWISE_PROXY_V2_URL}/integrations/agrian/recommendations`,
        {
          params,
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            }),
            common: {
              Authorization: `Bearer ${getAccessToken()}`
            }
          }
        }
      );
      setLoading(true);

      return promise
        .then(response => response)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const importRecommendations = useCallback(
    ({ recommendationsIds, seasonId }) => {
      const { promise } = agrianApi
        .createChildApi({
          action: 'integrations/agrian/recommendations'
        })
        .create(
          {
            ids: recommendationsIds,
            seasonId
          },
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => {
          if (res?.status === 207) {
            const errors = res?.data
              ?.filter(record => record.status === 'ERROR')
              .map(record => `${record.title}: ${record.message}`)
              .join('\n');
            toast.error(
              "Your recommendations were partially created. Some records weren't imported due to errors:",
              {
                actionButtonLabel: 'View imported recommendations',
                actionButton: () => history.replace(paths.recommendations),
                content: errors,
                supportButton: true,
                timeout: 60000
              }
            );
          } else {
            toast.success('Recommendations imported', {
              actionButton: () => history.replace(paths.recommendations)
            });
          }
          return res;
        })
        .catch(catchCancel)
        .catch(error => {
          if ([422, 400].includes(error.response?.status)) {
            const errorMessage = error.response?.data?.errors
              ? Object.values(error.response?.data?.errors).join('\n')
              : error.response?.data?.message;
            toast.error('Validation error while importing recommendations', {
              content: errorMessage,
              supportButton: true,
              timeout: 60000
            });
          } else {
            const serverError = createServerError({}, error);
            toast.error('Error while importing recommendations', {
              content: serverError.displayMessage,
              supportButton: true,
              timeout: 30000
            });
          }
          throw error;
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  return {
    authenticate,
    saveOrganizationMatch,
    growers,
    getAgrianRecommendationsBySeason,
    getAgrianRecommendationsBySeasonWithoutCache,
    importRecommendations,
    loading
  };
};

export default useAgrianApi;
