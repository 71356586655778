import { useState } from 'react';
import _ from 'lodash';
import {
  MASS_ASSIGNER_SUCCESS_COUNTER,
  RESET_MASS_ASSIGNER
} from 'reducers/reducer';
import getSeasons from 'screens/Yield/components/YieldActions/helpers/getSeasons';
import { getFeaturesCentroidCoordinates } from '../helpers/mapApiHelpers';
import useLandManagementData from './useLandManagementData';
import useCropZoneData from './useCropZoneData';
import { RESET_PRELOADED_PAGES } from '../helpers/propertyDataReducer';

const useMassAssigner = (
  massiveChangeSummary,
  fieldsToMassiveAssign,
  reloadData,
  dispatch
) => {
  const { isOrgInFF } = useLandManagementData();
  const {
    deleteCropZone,
    createBulkCropZone,
    updateBulkCropZone
  } = useCropZoneData();
  const [isSaving, setIsSaving] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleCancel = () => {
    dispatch({ type: RESET_MASS_ASSIGNER });
  };

  let allCropZones;
  const handleSaveCropZone = () => {
    let cropSeasonId;
    if (isOrgInFF) {
      cropSeasonId = massiveChangeSummary.season.id;
    }

    const newCropZone = {
      cropId: massiveChangeSummary.crop.id,
      cropSeasonId,
      cropZones: []
    };
    massiveChangeSummary.fieldsToMassiveAssign.forEach((field, index) => {
      newCropZone.cropZones.push({
        name: massiveChangeSummary.crop.name,
        propertyId: field.farmId,
        fieldId: field.id,
        cropId: massiveChangeSummary.crop.id,
        reportedArea: field.reportedArea,
        geometry: field.geometry,
        coordinates: getFeaturesCentroidCoordinates([field]).toString()
      });

      if (isOrgInFF) {
        newCropZone.cropZones[index].cropSeasonId =
          massiveChangeSummary.season.id;
      }
    });
    allCropZones = newCropZone;
    return [createBulkCropZone(newCropZone)];
  };

  const cropZonesToModify = () => {
    return _.flatten(
      massiveChangeSummary.fieldsToMassiveAssign.map(field =>
        field.cropzones.map(cropZone => {
          const cropSeasonObject = {};

          if (isOrgInFF) {
            const { cropSeasons } = cropZone;
            cropSeasonObject.cropSeasonId = cropSeasons[0].id;
          }

          return {
            ...cropZone,
            farmName: field.farmName,
            fieldName: field.name,
            fieldId: field.id,
            farmId: field.farmId,
            tags: massiveChangeSummary?.flags?.map(flag => flag.id) || [],
            ...cropSeasonObject
          };
        })
      )
    );
  };

  const updateBulkCropZoneTree = () => {
    let cropSeasonIds;
    if (isOrgInFF) {
      cropSeasonIds = getSeasons();
    }
    const newCropZonesUpdate = [];
    cropSeasonIds.map(season =>
      newCropZonesUpdate.push({
        cropId: massiveChangeSummary.crop.id,
        cropSeasonId: season,
        cropZones: []
      })
    );
    massiveChangeSummary.fieldsToMassiveAssign.map(field =>
      field.cropzones.forEach(cropZone => {
        const cropSeasonObject = {};
        if (isOrgInFF) {
          const { cropSeasons } = cropZone;
          cropSeasonObject.cropSeasonId = cropSeasons[0].id;
        }
        newCropZonesUpdate.forEach(seasonObject => {
          if (seasonObject.cropSeasonId === cropSeasonObject.cropSeasonId) {
            seasonObject.cropZones.push({
              boundaryArea: cropZone.boundaryArea,
              geometry: cropZone.geometry,
              reportedArea: cropZone.reportedArea,
              id: cropZone.id,
              name: massiveChangeSummary.crop.name,
              farmName: field.farmName,
              fieldName: field.name,
              fieldId: field.id,
              propertyId: field.farmId,
              tags: massiveChangeSummary?.flags?.map(flag => flag.id) ?? [],
              ...cropSeasonObject
            });
          }
        });
      })
    );
    return newCropZonesUpdate.filter(request => request.cropZones.length);
  };

  const handleDeleteCropZone = () => {
    allCropZones = cropZonesToModify();
    return allCropZones.map(async ({ id }) => deleteCropZone(id));
  };

  const handleUpdateCropZone = () => {
    allCropZones = updateBulkCropZoneTree();
    return allCropZones.map(bulkRequest => updateBulkCropZone(bulkRequest));
  };

  const toastContext = (fulfilled, cropZoneResolved) => {
    let toastDescription = '';
    if (massiveChangeSummary.action === 'Added') {
      toastDescription = `${fulfilled.length} of ${cropZoneResolved.length} crop zone(s) have been created`;
    } else if (massiveChangeSummary.action === 'Changed') {
      toastDescription = `${fulfilled.length} of ${cropZoneResolved.length} crop zone(s) have been updated`;
    } else if (massiveChangeSummary.action === 'TagsAdded') {
      toastDescription = `${fulfilled.length} crop zone(s) have been updated`;
    } else if (massiveChangeSummary.action === 'TagsDeleted') {
      toastDescription = `${fulfilled.length} crop zone(s) have been updated`;
    } else {
      toastDescription = `${fulfilled.length} of ${cropZoneResolved.length} crop zone(s) have been deleted`;
    }

    return toastDescription;
  };

  const toastGenerator = (fulfilled, cropZoneResolved) => {
    let toastTitle = '';
    if (massiveChangeSummary.action === 'TagsAdded') {
      toastTitle = 'Tags Added';
    } else if (massiveChangeSummary.action === 'TagsDeleted') {
      toastTitle = 'Crop Zone(s) Updated';
    } else {
      toastTitle = `Crop Zone(s) ${massiveChangeSummary.action}`;
    }

    const toastDescription = toastContext(fulfilled, cropZoneResolved);
    let toastType = '';
    if (fulfilled.length === cropZoneResolved.length) {
      toastType = 'success';
    } else if (
      fulfilled.length > 0 &&
      fulfilled.length < cropZoneResolved.length
    ) {
      toastType = 'warning';
    } else {
      toastType = 'error';
    }

    return { toastTitle, toastDescription, toastType };
  };

  const handleComplete = async () => {
    let totalCropZones;
    setIsSaving(true);
    const actions = {
      Added: async () => Promise.allSettled(handleSaveCropZone()),
      Deleted: async () => Promise.allSettled(handleDeleteCropZone()),
      Changed: async () => Promise.allSettled(handleUpdateCropZone()),
      TagsAdded: async () => Promise.allSettled(handleUpdateCropZone()),
      TagsDeleted: async () => Promise.allSettled(handleUpdateCropZone())
    };
    const allRequests = await actions[massiveChangeSummary.action]();
    const enumerateCropZonesFromRequest = requests => {
      return _.flatMap(requests.map(request => request?.value?.data));
    };
    const fulfilledCropZones = enumerateCropZonesFromRequest(
      allRequests.filter(resolved => resolved.status === 'fulfilled')
    );
    if (massiveChangeSummary.action === 'Deleted') {
      totalCropZones = allCropZones;
    } else {
      totalCropZones = allCropZones?.length
        ? _.flatMap(
            allCropZones.map(cropZonesObj =>
              cropZonesObj.cropZones.map(cz => cz)
            )
          )
        : allCropZones.cropZones.map(cz => cz);
    }
    const { toastTitle, toastDescription, toastType } = toastGenerator(
      fulfilledCropZones,
      totalCropZones
    );
    dispatch({
      type: RESET_PRELOADED_PAGES
    });
    reloadData();

    dispatch({
      type: MASS_ASSIGNER_SUCCESS_COUNTER,
      payload: {
        toastTitle,
        toastDescription,
        toastType,
        action: massiveChangeSummary.action
      }
    });

    dispatch({ type: RESET_MASS_ASSIGNER });

    return allRequests;
  };

  const checkDeleteAction = () => {
    if (massiveChangeSummary.action !== 'Added') {
      setOpenConfirmModal(true);
    } else {
      handleComplete();
    }
  };

  const getTotalCropzones = () => {
    const elements = fieldsToMassiveAssign.flatMap(field =>
      field.cropzones.map(cropzone => cropzone)
    );
    return elements?.length || 0;
  };

  return {
    handleCancel,
    isSaving,
    checkDeleteAction,
    getTotalCropzones,
    openConfirmModal,
    handleComplete,
    setOpenConfirmModal,
    cropZonesToModify
  };
};

export default useMassAssigner;
